import * as React from 'react';
import { Typography } from 'antd';
import Box from 'ui-box';
import { t } from 'i18next';

import './PlanDescription.less';

const { Title } = Typography;

export default () => (
  <Box>
    <Title level={4} className='plans-pricing-list-item-title'>
      {t('plan_usage.alemHealth_connect_free_month')}
    </Title>
    <Typography className='plans-pricing-list-item-desc'>
      {t('plan_usage.start_building_teleradiology_today')}
    </Typography>
  </Box>
);
