/* eslint-disable */
import * as React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Menu, Icon, Layout, Avatar, Alert, Progress, Button } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import config from '../../utils/config';
import store from 'store';
import { getFormValues } from 'redux-form';
import { t } from 'i18next';
import {
  logout,
  getStripeDataAction,
  getUserStudyCountRequest
} from '../../store/actions';

import './Header.less';
import { ONBOARDING_FORM } from '../../utils/form-helpers';

const { SubMenu } = Menu;

type Props = {
  fixed: boolean,
  avatar: string,
  username: string,
  collapsed: boolean,
  onSignOut: () => void,
  getStripeData: () => void,
  getUserStudyCount: () => void,
  onCollapseChange: boolean => void
};

const getOnboardingsteps = onboardingForm => {
  if (onboardingForm && onboardingForm.onboardingSteps) {
    return onboardingForm.onboardingSteps;
  }

  if (store.get('onboardingSteps')) {
    return store.get('onboardingSteps');
  }

  return [
    {
      id: 1,
      title: t('onboarding.sign_alemHealth_connect'),
      time: 3,
      isDone: true
    },
    { id: 2, title: t('onboarding.invite_radiologists'), time: 3, isDone: false },
    { id: 3, title: t('onboarding.invite_colleagues'), time: 3, isDone: false },
    {
      id: 4,
      title: t('onboarding.add_facilities_report_for'),
      time: 3,
      isDone: false
    },
    { id: 5, title: t('onboarding.order_alemHealth_hardware'), time: 3, isDone: false },
    { id: 6, title: t('onboarding.activate_web_uploader'), time: 3, isDone: false },
    { id: 7, title: t('onboarding.set_your_branding'), time: 3, isDone: false },
    { id: 8, title: t('onboarding.add_referring_physicians'), time: 3, isDone: false }
  ];
};

export default connect(
  state => ({
    payments: state.payments.data,
    studyCount: state.profile.studyCount,
    onboardingSteps: getOnboardingsteps(getFormValues(ONBOARDING_FORM)(state))
  }),
  {
    onSignOut: logout,
    getStripeData: getStripeDataAction,
    getUserStudyCount: getUserStudyCountRequest
  }
)((props: Props) => {
  const handleClickMenu = e => {
    if (e.key === 'SignOut') {
      props.onSignOut();
    }
  };

  const {
    fixed,
    avatar,
    username,
    collapsed,
    onCollapseChange,
    getStripeData,
    getUserStudyCount,
    studyCount,
    payments,
    onboardingSteps
  } = props;

  const history = useHistory();

  React.useEffect(() => {
    getStripeData();
  }, []);

  React.useEffect(() => {
    if (
      payments &&
      payments.subscriptions &&
      payments.subscriptions.id !== '' &&
      payments.subscriptions.main.name === 'AlemHealth Connect Lite'
    ) {
      const startDate = moment(
        payments.subscriptions.main.currentPeriodStart
      ).format('l');
      const endDate = moment().format('l');
      getUserStudyCount({ startDate, endDate });
    }
  }, [payments]);

  /* rightContent.unshift(
      <Popover
        placement='bottomRight'
        trigger='click'
        key='notifications'
        overlayClassName='notificationPopover'
        getPopupContainer={() => document.querySelector('#layoutHeader')}
        content={
          <div className='notification'>
            <List
              itemLayout='horizontal'
              dataSource={notifications ? notifications : []}
              locale={{
                emptyText: 'You have viewed all notifications'
              }}
              renderItem={item => (
                <List.Item className='notificationItem'>
                  <List.Item.Meta
                    title={
                      <Ellipsis tooltip lines={1}>
                        {item.title}
                      </Ellipsis>
                    }
                    description={moment(item.date).fromNow()}
                  />
                  <Icon
                    style={{ fontSize: 10, color: '#ccc' }}
                    type='right'
                    theme='outlined'
                  />
                </List.Item>
              )}
            />
            {notifications.length ? (
              <div
                onClick={onAllNotificationsRead}
                role='link'
                tabIndex='-1'
                className='clearButton'
              >
                Clear notifications
              </div>
            ) : null}
          </div>
        }
      >
        <Badge
          count={notifications.length}
          dot
          offset={[-10, 10]}
          className='iconButton'
        >
          <Icon className='iconFont' type='bell' />
        </Badge>
      </Popover>
    ); */

  const renderPastDueAlert = () => {
    if (
      payments &&
      payments.subscriptions &&
      payments.subscriptions.main &&
      payments.subscriptions.main.status === 'past_due'
    ) {
      return (
        <Alert
          type={'error'}
          message={
            <div>
              Your account is past due
              <Link style={{ marginLeft: 15 }} to='/subscriptions'>
                Get unlimited access
              </Link>
            </div>
          }
        />
      );
    }
  };

  const renderRemainingTrialDays = () => {
    if (
      payments &&
      payments.subscriptions &&
      payments.subscriptions.main &&
      payments.subscriptions.main.status === 'trialing'
    ) {
      let diff = moment(payments.renewal).diff(moment(), 'days');
      if (diff <= 0) {
        diff = 0;
      }
      return (
        <Alert
          type={diff === 0 ? 'error' : 'info'}
          message={
            <div>
              You have {diff} day{diff != 1 ? 's ' : ' '}
              left in your free trial days remaining{' '}
              <Link style={{ marginLeft: 15 }} to='/subscriptions'>
                Get unlimited access
              </Link>
            </div>
          }
        />
      );
    }
  };

  const renderRemainingUploads = () => {
    const studyCountUpdated = studyCount > 25 ? 25 : studyCount;
    if (
      studyCount !== undefined &&
      studyCount >= 0 &&
      payments &&
      payments.subscriptions &&
      payments.subscriptions.main &&
      payments.subscriptions.main.name === 'AlemHealth Connect Lite'
    ) {
      return (
        <Alert
          type={studyCountUpdated === 25 ? 'error' : 'info'}
          message={
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  marginLeft: '20px',
                  display: 'flex',
                  minWidth: '200px'
                }}
              >
                <Progress
                  percent={(studyCountUpdated / 25) * 100}
                  showInfo={false}
                />
              </div>
              <div style={{ fontSize: 12, marginLeft: '5px' }}>
                {studyCountUpdated} / 25 Uploads
              </div>
              <Link style={{ marginLeft: 15 }} to='/subscriptions'>
                Get unlimited access
              </Link>
            </div>
          }
        />
      );
    }
  };

  const renderOnboardingButton = () => {
    const studyCountUpdated = studyCount > 25 ? 25 : studyCount;
    const data = onboardingSteps;
    const remainingTime =
      data &&
      data.reduce((total = 0, item) => {
        if (item.isDone) {
          return total + 0;
        }
        return total + item.time;
      }, 0);

    const totalTime =
      data &&
      data.reduce((total = 0, item) => {
        return total + item.time;
      }, 0);

    const completion = ((totalTime - remainingTime) / totalTime) * 100;

    if (completion !== 100) {
      return (
        <div style={{ display: 'flex' }}>
          <Button
            style={{ marginLeft: 10 }}
            type='primary'
            onClick={() => history.push('/onboarding')}
          >
            {t('header.get_started')} ({completion}%)
          </Button>
        </div>
      );
    }
  };

  const rightContent = [
    <Menu key='user' mode='horizontal' onClick={handleClickMenu}>
      <SubMenu
        title={
          <React.Fragment>
            <Avatar style={{ marginRight: 8 }} src={avatar} />
            <span>{username}</span>
          </React.Fragment>
        }
      >
        <Menu.Item key='SignOut'>
          <Link to='/'>Sign out</Link>
        </Menu.Item>
      </SubMenu>
    </Menu>
  ];

  return (
    <Layout.Header
      className={classnames('header', {
        fixed,
        collapsed
      })}
      id='layoutHeader'
    >
      <div className='leftContainer' style={{ flex: 1 }}>
        <div className='brand'>
          <div className='logo'>
            <img alt='logo' src={config.basic.logoPath} />
            {collapsed ? null : (
              <img
                alt='logo'
                className='name'
                src={config.basic.namePath}
                style={{ height: '100%', width: '170px' }}
              />
            )}
          </div>
        </div>
        <div
          className='button'
          role='button'
          tabIndex='-1'
          onClick={onCollapseChange.bind(this, !collapsed)}
        >
          <Icon
            type={classnames({
              'menu-unfold': collapsed,
              'menu-fold': !collapsed
            })}
          />
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <div>{renderRemainingTrialDays()}</div>
          <div>{renderRemainingUploads()}</div>
          <div>{renderPastDueAlert()}</div>
        </div>
      </div>

      <div className='rightContainer'>
        {renderOnboardingButton()}
        {rightContent}
      </div>
    </Layout.Header>
  );
});
