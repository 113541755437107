import { call, put, select, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '../helpers';
import * as actions from '../actions';
import {
  createInternalRadiologistApiCall,
  deleteInternalRadiologistApiCall,
  getInternalRadiologistByIdApiCall,
  getInternalRadiologistsApiCall,
  updateInternalRadiologistApiCall
} from '../../api';
import { getCurrentUser } from '../selectors';
import { getInternalLastSearch } from '../selectors/radiologistsSelectors';

function* getInternalRadiologistsSaga(action) {
  yield put({ type: actions.GET_INTERNAL_RADS_LOADING, loading: true });
  try {
    const { perPage, page, sort, query } = action;
    const profile = yield select(getCurrentUser);
    const radiologists = yield call(
      getInternalRadiologistsApiCall,
      profile,
      perPage,
      page,
      sort,
      query
    );
    yield put({
      type: actions.UPDATE_LAST_INTERNAL_RADIOLOGISTS_SEARCH,
      search: { perPage, page, sort, query }
    });
    yield put({
      type: actions.GET_INTERNAL_RADIOLOGISTS_SUCCESS,
      data: radiologists
    });
  } catch (error) {
    yield put({ type: actions.GET_INTERNAL_RADIOLOGISTS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_INTERNAL_RADS_LOADING, loading: false });
  }
}

function* getInternalRadiologistByIdSaga(action) {
  yield put({ type: actions.GET_INTERNAL_RAD_LOADING, loading: true });
  try {
    const { radId } = action;
    const profile = yield select(getCurrentUser);
    const rad = yield call(getInternalRadiologistByIdApiCall, profile, radId);
    yield put({ type: actions.GET_INTERNAL_RAD_BY_ID_SUCCESS, data: rad });
  } catch (error) {
    yield put({ type: actions.GET_INTERNAL_RAD_BY_ID_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_INTERNAL_RAD_LOADING, loading: false });
  }
}

function* addInternalRadiologistSaga(action) {
  yield put({ type: actions.ADD_INTERNAL_RAD_LOADING, loading: true });
  try {
    const { radiologist } = action;
    const profile = yield select(getCurrentUser);
    yield call(createInternalRadiologistApiCall, profile, radiologist);
    let lastSearch = yield select(getInternalLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getInternalRadiologistsSaga, lastSearch);
    yield put({ type: actions.ADD_INTERNAL_RAD_SUCCESS });
    yield call(showSuccess, 'Internal radiologist added');
  } catch (error) {
    yield put({ type: actions.ADD_INTERNAL_RAD_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.ADD_INTERNAL_RAD_LOADING, loading: false });
  }
}

function* editInternalRadiologistSaga(action) {
  yield put({ type: actions.EDIT_INTERNAL_RAD_LOADING, loading: true });
  try {
    const { radiologist, radId } = action;
    const profile = yield select(getCurrentUser);
    yield call(updateInternalRadiologistApiCall, profile, radId, radiologist);
    let lastSearch = yield select(getInternalLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getInternalRadiologistsSaga, lastSearch);
    yield put({ type: actions.EDIT_INTERNAL_RAD_SUCCESS });
    yield call(showSuccess, `Internal radiologist has been updated`);
  } catch (error) {
    yield put({ type: actions.EDIT_INTERNAL_RAD_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.EDIT_INTERNAL_RAD_LOADING, loading: false });
  }
}

function* deleteInternalRadiologistSaga(action) {
  yield put({ type: actions.DELETE_INTERNAL_RAD_LOADING, loading: true });
  try {
    const { radId } = action;
    const profile = yield select(getCurrentUser);
    yield call(deleteInternalRadiologistApiCall, profile, radId);
    let lastSearch = yield select(getInternalLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getInternalRadiologistsSaga, lastSearch);
    yield put({ type: actions.DELETE_EXTERNAL_RAD_SUCCESS });
    yield call(showSuccess, 'Radiologist has been deleted');
  } catch (error) {
    yield put({ type: actions.DELETE_EXTERNAL_RAD_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.EDIT_EXTERNAL_RAD_LOADING, loading: false });
  }
}

export default function* internalRadiologistsSaga() {
  yield takeLatest(
    actions.GET_INTERNAL_RADIOLOGISTS_REQUEST,
    getInternalRadiologistsSaga
  );
  yield takeLatest(
    actions.GET_INTERNAL_RAD_BY_ID_REQUEST,
    getInternalRadiologistByIdSaga
  );
  yield takeLatest(
    actions.ADD_INTERNAL_RAD_REQUEST,
    addInternalRadiologistSaga
  );
  yield takeLatest(
    actions.EDIT_INTERNAL_RAD_REQUEST,
    editInternalRadiologistSaga
  );
  yield takeLatest(
    actions.DELETE_INTERNAL_RAD_REQUEST,
    deleteInternalRadiologistSaga
  );
}
