import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import profile from './profileReducer';
import aspect from './interfaceReducer';
import loading from './loadingReducer';
import users from './usersReducer';
import externalRadiologists from './externalRadiologistsReducer';
import internalRadiologists from './internalRadiologistsReducer';
import groupInvitations from './groupInvitationsReducer';
import providers from './providersReducer';
import initialForm from './formReducer';
import countryList from './countryListReducer';
import plan from './planReducer';
import facilities from './facilityReducer';
import group from './groupReducer';
import physicians from './physiciansReducer';
import externalPhysicians from './externalPhysiciansReducer';
import dashboard from './dashboardReducer';
import payments from './paymentsReducer';
import secondOpinion from './secondOpinionReducer';
import devices from './devicesReducer';
import location from './location';
import referringFacilities from './referringFacilityReducer';
import referringPhysicians from './referringPhysicianReducer';

export default combineReducers({
  initialForm,
  dashboard,
  form,
  group,
  profile,
  aspect,
  loading,
  users,
  externalRadiologists,
  internalRadiologists,
  providers,
  countryList,
  plan,
  devices,
  facilities,
  physicians,
  externalPhysicians,
  payments,
  secondOpinion,
  groupInvitations,
  location,
  referringFacilities,
  referringPhysicians
});
