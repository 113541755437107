import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/facilityActions';
import {
  GET_SOURCE_FACILITIES_CHART_LOADING,
  ADD_FACILITY_LOADING,
  DELETE_FACILITY_LOADING,
  EDIT_FACILITY_LOADING,
  GET_FACILITIES_LOADING,
  GET_FACILITY_LOADING
} from '../actions/loadingActions';

import { getFormValues, showError, showSuccess } from '../helpers';
import {
  createFacility,
  getExternalFacilities,
  getFacilityById,
  getIngroupFacilities,
  updateFacility,
  getSourceFacilitiesChart
} from '../../api/endpoints/facilities';
import { facilityToJson } from '../../api/transformers';
import {
  getCurrentUser,
  getAffiliatedGroup,
  getFacilityLetterhead,
  getFacilityLogo
} from '../selectors';
import { SOURCE_FACILITIES_CHART_FORM_NAME } from '../../utils/form-helpers';
import { createGroupApiCall, updateGroupAPiCall } from '../../api';

function* getInternalFacilitiesSaga() {
  yield put({ type: GET_FACILITIES_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const facilities = yield call(getIngroupFacilities, profile);
    yield put({ type: actions.GET_INTERNAL_FACILITIES_SUCCESS, facilities });
  } catch (error) {
    yield put({ type: actions.GET_INTERNAL_FACILITIES_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_FACILITIES_LOADING, loading: false });
  }
}

function* getExternalFacilitiesSaga() {
  yield put({ type: GET_FACILITIES_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const facilities = yield call(getExternalFacilities, profile);
    yield put({ type: actions.GET_EXTERNAL_FACILITIES_SUCCESS, facilities });
  } catch (error) {
    yield put({ type: actions.GET_EXTERNAL_FACILITIES_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_FACILITIES_LOADING, loading: false });
  }
}

function* getFacilityByIdSaga(action) {
  yield put({ type: GET_FACILITY_LOADING, loading: true });
  try {
    const { facilityId } = action;
    const profile = yield select(getCurrentUser);
    const facility = yield call(getFacilityById, profile, facilityId);
    yield put({ type: actions.GET_FACILITY_BY_ID_SUCCESS, facility });
  } catch (error) {
    yield put({ type: actions.GET_FACILITY_BY_ID_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_FACILITY_LOADING, loading: false });
  }
}

function* createFacilitySaga(action) {
  yield put({ type: ADD_FACILITY_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    let facility = {};
    let res = {};
    if (action.type === actions.ADD_INTERNAL_FACILITY_REQUEST) {
      const group = yield select(getAffiliatedGroup);
      const facilityLogo = yield select(getFacilityLogo);
      const letterhead = yield select(getFacilityLetterhead);
      facility = yield call(
        facilityToJson,
        { letterhead, facilityLogo, ...action.facility },
        group
      );
      res = yield call(createFacility, profile, facility);
      yield call(getInternalFacilitiesSaga);
    } else {
      const { group_id: groupId } = yield call(
        createGroupApiCall,
        profile,
        action.facility
      );
      if (groupId) {
        facility = yield call(facilityToJson, action.facility, groupId);
        res = yield call(createFacility, profile, facility);
        yield call(updateGroupAPiCall, profile, {
          groupId,
          availableTo: groupId
        });
        yield call(getExternalFacilitiesSaga);
      }
    }
    yield put(actions.addNewlyCreatedFacility({ guid: res.id, name: res.name }))
    yield call(showSuccess, 'New facility added');
    yield put({ type: actions.ADD_FACILITY_SUCCESS });
  } catch (error) {
    yield put({ type: actions.ADD_FACILITY_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: ADD_FACILITY_LOADING, loading: false });
  }
}

function* updateFacilitySaga(action) {
  yield put({ type: EDIT_FACILITY_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    let { facility, facilityId } = action;
    if (action.type === actions.EDIT_INTERNAL_FACILITY_REQUEST) {
      const facilityLogo = yield select(getFacilityLogo);
      const letterhead = yield select(getFacilityLetterhead);
      facility = yield call(facilityToJson, {
        ...facility,
        facilityLogo,
        letterhead
      });
      yield call(updateFacility, profile, facility, facilityId);
    } else {
      facility = yield call(facilityToJson, facility);
      yield call(updateFacility, profile, facility, facilityId);
    }
    yield call(showSuccess, 'Facility updated');
    yield call(getInternalFacilitiesSaga);
    yield put({ type: actions.EDIT_FACILITY_SUCCESS });
  } catch (error) {
    yield put({ type: actions.EDIT_FACILITY_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: EDIT_FACILITY_LOADING, loading: false });
    yield put({ type: actions.UPLOAD_FACILITY_LOGO });
  }
}

function* getSourceFacilitiesChartSaga() {
  yield put({ type: GET_SOURCE_FACILITIES_CHART_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const filters = yield select(
      getFormValues,
      SOURCE_FACILITIES_CHART_FORM_NAME
    );
    const chart = yield call(getSourceFacilitiesChart, profile, filters);
    yield put({ type: actions.GET_SOURCE_FACILITIES_CHART_SUCCESS, chart });
  } catch (error) {
    yield put({ type: actions.GET_SOURCE_FACILITIES_CHART_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_SOURCE_FACILITIES_CHART_LOADING, loading: false });
  }
}

function* deleteFacilitySaga(action) {
  yield put({ type: DELETE_FACILITY_LOADING, loading: true });
  try {
    yield put({ type: actions.DELETE_FACILITY_SUCCESS });
  } catch (error) {
    yield put({ type: actions.DELETE_FACILITY_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: DELETE_FACILITY_LOADING, loading: false });
  }
}

export default function* facilitySaga() {
  yield takeLatest(
    actions.GET_INTERNAL_FACILITIES_REQUEST,
    getInternalFacilitiesSaga
  );
  yield takeLatest(
    actions.GET_EXTERNAL_FACILITIES_REQUEST,
    getExternalFacilitiesSaga
  );
  yield takeLatest(actions.GET_FACILITY_BY_ID_REQUEST, getFacilityByIdSaga);
  yield takeLatest(actions.ADD_EXTERNAL_FACILITY_REQUEST, createFacilitySaga);
  yield takeLatest(actions.ADD_INTERNAL_FACILITY_REQUEST, createFacilitySaga);
  yield takeLatest(actions.EDIT_INTERNAL_FACILITY_REQUEST, updateFacilitySaga);
  yield takeLatest(actions.DELETE_FACILITY_REQUEST, deleteFacilitySaga);
  yield takeLatest(
    actions.GET_SOURCE_FACILITIES_CHART_REQUEST,
    getSourceFacilitiesChartSaga
  );
}
