import React, { useState } from 'react';
import { Button, Card, Col, Icon, Row, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import Box from 'ui-box';
import { Link } from 'react-router-dom';

import { t } from 'i18next';
import { StripeSpec } from '../../../../utils/stripe';
import { showError, showSuccess } from '../../../../store/helpers';
import { createStripeChargeEndpoint } from '../../../../api/endpoints/payments';
import { getCurrentUser } from '../../../../store/selectors';
import PlanModal from './PlanModal';
import './PlanPricingCard.less';
import { postNotificationToZapierAPICall } from '../../../../api/endpoints/zapier';

const { Title } = Typography;

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PRODUCTION === '0'
    ? process.env.REACT_APP_STRIPE_TEST_API_KEY
    : process.env.REACT_APP_STRIPE_API_KEY
);

type Props = StripeSpec & { id: string, email: string };

export default ({
  id,
  email,
  priceId,
  quantity,
  name,
  price,
  currency,
  features,
  learnMore,
  showButton,
  showModal
}: Props) => {
  const currentLocation = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const profile = useSelector(getCurrentUser);
  const createStripeSession = async () =>
    createStripeChargeEndpoint(profile, {
      items:
        priceId.split('_') === 'plan'
          ? [{ price: priceId, quantity }]
          : [{ price: priceId, quantity }],
      success: `${currentLocation}?stripe=success`,
      cancel: `${currentLocation}?stripe=error`
    });

  const [visible, setVisibility] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numberOfAlemBox, setNumberOfAlemBox] = useState(1);
  const [numberOfAlemHub, setNumberOfAlemHub] = useState(1);

  const stripeCheckout = async (_event: any) => {
    const stripe = await stripePromise;
    try {
      setLoading(true);
      const token = await createStripeSession();
      const { error } = await stripe.redirectToCheckout({
        sessionId: token
      });
      if (error && error.message) {
        showError(error.message);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Something went wrong creating checkout session: `, e);
      showError(
        new Error(`Something went wrong creating checkout session: ${e.message}.
      Please, reload the page and contact us if the problem persists.`)
      );
    } finally {
      setLoading(false);
    }
  };

  const checkout = async (_event: any) => {
    if (showModal) {
      setLoading(true);
      const content = { plan: name, numberOfAlemBox, numberOfAlemHub };
      const isZapierOk = await postNotificationToZapierAPICall({ content });
      if (isZapierOk) {
        setLoading(false);
        setVisibility(false);
        showSuccess(
          'Your request completed successfully. Our implementation engineers will get in touch with you.'
        );
      } else {
        setLoading(false);
        setVisibility(false);
        showError(
          new Error(
            `Something went wrong.Please, reload the page and contact us if the problem persists.`
          )
        );
      }
    } else {
      await stripeCheckout();
    }
  };

  const openModal = () => {
    setVisibility(true);
  };

  return (
    <>
      {showModal ? (
        <PlanModal
          visible={visible}
          // eslint-disable-next-line no-shadow
          setVisibility={visible => setVisibility(visible)}
          checkout={checkout}
          setNumberOfAlemBox={setNumberOfAlemBox}
          setNumberOfAlemHub={setNumberOfAlemHub}
          name={name}
          isLoading={loading}
        />
      ) : null}
      <Card className='subscriptions-card'>
        <p className='plan-name'>{name}</p>
        <Title className='subscriptions-price' style={{ color: '#15a9e1' }}>
          {price === 0 ? (
            'Free'
          ) : (
            <>
              <span className='plan-price-currency'>$ </span>
              <span>{price}</span>
            </>
          )}
        </Title>
        <p className='plan-billing'>{t('plan_usage.per_facility_month')}</p>
        <ul className='plan-features'>
          {features.map(feature => (
            <li key={feature}>{t(`plan_usage.${feature}`)}</li>
          ))}
        </ul>
        {learnMore && (
          <Box marginBottom={40}>
            <Row type='flex' justify='end'>
              <Col>
                <Link to={learnMore} className='plan-learn-more-link'>
                  {t('plan_usage.learn_more')}
                </Link>
              </Col>
            </Row>
          </Box>
        )}
        {showButton && (
          <Button
            type='primary'
            className='plan-main-action'
            onClick={showModal ? openModal : checkout}
          >
            {t('plan_usage.upgrade_me')} <Icon type='arrow-right' />
          </Button>
        )}
      </Card>
    </>
  );
};
