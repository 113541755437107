import React from 'react';
import { Form, Radio } from 'antd';
import { t } from 'i18next';

const styles = {
  radioBtn: {
    border: 'none',
    outline: 'none',
    padding: '0 8px',
    boxShadow: 'none',
    position: 'inherit'
  },
  fullWidth: {
    width: '50%',
    textAlign: 'center'
  }
};

const RadioGroupField = ({
  labelStyle = {},
  label = '',
  labelAlign = 'right',
  colon = false,
  fullWidth = false,
  width = '',
  maxWidth = '',
  minWidth = '',
  options = [],
  buttonStyle = 'outline',
  radioBtnType = '',
  radioBtnStyle = fullWidth ? styles.fullWidth : {},
  formItemLayout = {},
  input,
  meta: { touched, error },
  ...restProps
}) => {
  return (
    <Form.Item
      style={labelStyle}
      label={label}
      labelAlign={labelAlign}
      colon={colon}
      validateStatus='' // 'success' 'warning' 'error' 'validating'
      help={touched && error ? error : ''}
      {...formItemLayout}
    >
      <Radio.Group
        style={{
          width: fullWidth ? '100%' : width,
          maxWidth,
          minWidth: fullWidth ? '200px' : minWidth,
          display: 'flex',
          justifyContent: 'space-evenly'
        }}
        {...input}
        buttonStyle={buttonStyle}
      >
        {/* eslint-disable-next-line */}
        {options.map(({ value, label }) => (
          <Radio.Button
            style={radioBtnType === 'outline' ? styles.radioBtn : radioBtnStyle}
            key={value}
            value={value}
          >
            {t(label)}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};

export default RadioGroupField;
