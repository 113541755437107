import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import createStore from './store';
import BaseLayout from './layouts/BaseLayout';
import { pages } from './utils/routes';
import { Login } from './pages';
import IndexUser from './pages/users';
import IndexPlan from './pages/payments';
import IndexFacilities from './pages/facilities';
import IndexSettings from './pages/settings';
import IndexRadiologist from './pages/radiologist';
import IndexPhysicians from './pages/physicians';
import ReferralPatterns from './pages/referral/referral-patterns';
import ReferringPhysicians from './pages/referral/referring-physicians';
import ReferringFacilities from './pages/referral/referring-facilities';
import Auth0Callback from './pages/auth0_callback';
import RequestServiceProvider from './pages/radiologist/RequestService';
import Persistor from './components/Persistor';
import Dashboard from './pages/dashboard/container';
import Onboarding from './pages/onboarding';
import OnPremisesDevices from './pages/devices/on-premises';
import CloudDevices from './pages/devices/cloud';
import IndexSecondOpinionBranding from './pages/second-opinion/branding';
import IndexSecondOpinionUploader from './pages/second-opinion/uploader-settings';
import UtilisationRates from './pages/utilisation/container';
import './i18n';

import './themes/index.less';
import NoAdmin from './pages/no_admin';
import { handleAuthentication } from './api/auth/auth_v2';

const App = () => {
  const { store, persistor } = createStore();
  return (
    <Provider store={store}>
      <Persistor persistor={persistor}>
        <Router>
          <BaseLayout>
            <Fragment>
              <Route
                exact
                path='/auth0-callback'
                render={props => {
                  handleAuthentication(props);
                  return <Auth0Callback {...props} />;
                }}
              />
              <Route path={pages.noAdmin} component={NoAdmin} />
              <Route path={pages.login} component={Login} />
              <Route path={pages.dashboard} component={Dashboard} />
              <Route
                path={pages.referralPatterns}
                component={ReferralPatterns}
              />
              <Route
                path={pages.referringFacilities}
                component={ReferringFacilities}
              />
              <Route
                path={pages.referringPhysicians}
                component={ReferringPhysicians}
              />
              <Route path={pages.user} component={IndexUser} />
              <Route path={pages.facilities} component={IndexFacilities} />
              <Route path={pages.phisicians} component={IndexPhysicians} />
              <Route
                exact
                path={pages.requestServiceProvider}
                component={RequestServiceProvider}
              />
              <Route
                exact
                path={pages.radiologists}
                component={IndexRadiologist}
              />
              {/* <Route path={pages.plan} component={IndexPlan} /> */}
              <Route
                path={pages.secondOpinionBranding}
                component={IndexSecondOpinionBranding}
              />
              <Route
                path={pages.secondOpinionUploader}
                component={IndexSecondOpinionUploader}
              />
              {/* <Route
                path='/network/network_invitations'
                component={() => <div>network invitations</div>}
              />

              {/* <Route path='/support' component={Login} /> */}
              <Route path={pages.settings} component={IndexSettings} />
              <Route path={pages.onboarding} component={Onboarding} />
              <Route
                path={pages.onPremisesDevices}
                component={OnPremisesDevices}
              />
              <Route path={pages.cloudDevices} component={CloudDevices} />
              <Route
                path={pages.utilisationRates}
                component={UtilisationRates}
              />
            </Fragment>
          </BaseLayout>
        </Router>
      </Persistor>
    </Provider>
  );
};

export default App;
