// @flow

/**
 * Summary
 *
 * The external radiologist endpoint retrieves those radiologists that don't belong to the affiliated group of the user.
 * To find what radiologists can be seen by the user, the api uses the affiliated groups field of the user app metadata.
 * External radiologists can be retrieved by list and by id, but cannot be created, but a create method allows the
 * backend to send an invite to him to join the users affiliated group. This should be taken into account by the
 * frontend to show that him has been invited. Also, a delete method is provided to delete the user associated group
 * from the external radiologists affiliated groups.
 *
 */
import AuthService from '../index';
import Logger from '../../utils/logger';
import querier from './querier';
import { jsonToUser } from '../transformers';

import type {
  RadiologistInvitation,
  UserProfile,
  MultipleExternalRadiologistsResponse,
  ApiService
} from '../../schemas';

const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/external-radiologists';

/**
 * Get the external radiologists list belonging to the user affiliated group. It accepts a series of methods to filter
 * the results.
 * @param profile: user profile
 * @param perPage: number of results to get by page
 * @param page: page of results
 * @param sort: column to order the returned results.
 * @param query: a query string that represents additional queries to pass to limit user results. If multiple queries
 * shall be passed, they must be separated by a plus ("+") sign. Each query follows the lucene query format (key:value)
 * For example: `name:Mark+email:mark@radiologist.com`
 * @returns {never|Promise<Response>} A promise with an http response containing a list of radiologist
 */
export const getExternalRadiologistsApiCall = async (
  profile: UserProfile,
  perPage: number,
  page: number,
  sort: string,
  query: string
) => {
  let route = BASE_ROUTE;
  if (perPage > 0 || page > 0 || sort.length > 0 || query.length > 0) {
    route += querier(perPage, page, sort, query);
  }
  Logger.log('GET External radiologists api call with route: ', route);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, route, {})
    .then((response: MultipleExternalRadiologistsResponse) => {
      Logger.log('GET External radiologists api response: ', response);
      return {
        array: response.external_radiologists.map(r => jsonToUser(r)),
        total: response.total
      };
    });
};

/**
 * Retrieves a radiologist by id
 * @param id: id of the radiologist to retrieve
 * @param profile: user profile
 * @returns {never|Promise<*>}: the radiologist with the provided id
 */
export const getExternalRadiologistByIdApiCall = async (
  profile: UserProfile,
  id: string
) => {
  Logger.log('GET External radiologist by id api call');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {})
    .then(r => {
      Logger.log('GET External radiologist by id api response: ', r);
      return jsonToUser(r);
    });
};

/**
 * Invites a radiologist to join the user affiliated groups. Required admin privileges.
 * @param profile: user profile
 * @param radiologistInvitation: data to contact the desired radiologist.
 * @returns {never|Promise<*>}:
 */
export const createExternalRadiologistApiCall = async (
  profile: UserProfile,
  radiologistInvitation: RadiologistInvitation
) => {
  Logger.log(
    'POST external radiologist api call with invitation: ',
    radiologistInvitation
  );
  const obj = {
    userId: profile.id,
    email: profile.email,
    groupGUID: profile.appMetadata.affiliatedGroup,
    radName: radiologistInvitation.name,
    radEmail: radiologistInvitation.invitee_email,
    radTitle:
      radiologistInvitation.userMetadata &&
      radiologistInvitation.userMetadata.title,
    radCredentials: radiologistInvitation.credentials,
    radUserType:
      radiologistInvitation.userMetadata &&
      radiologistInvitation.userMetadata.userType,
    radPhoneNumber:
      radiologistInvitation.userMetadata &&
      radiologistInvitation.userMetadata.phoneNumber,
    radSpecialty:
      radiologistInvitation.userMetadata &&
      radiologistInvitation.userMetadata.specialty
  };
  await fetch('https://hooks.zapier.com/hooks/catch/599828/o43r3pz/', {
    method: 'POST',
    body: JSON.stringify(obj),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  return { message: 'Invitation Sent.' };
  // const Auth = await AuthService(profile.accessToken.length > 0);
  // return new Auth(profile)
  //   .service(API_SERVICE, BASE_ROUTE, {
  //     method: 'POST',
  //     body: JSON.stringify(radiologistInvitation)
  //   })
  //   .then(r => {
  //     Logger.log('POST external radiologist api call response: ', r);
  //     return r;
  //   });
};

/**
 * Deletes the user affiliated group from the radiologists associated group. Requires admin privileges.
 * @param profile: user profile
 * @param id: id of the radiologist to delete.
 * @returns {never|Promise<*>}
 */
export const deleteExternalRadiologistApiCall = async (
  profile: UserProfile,
  id: string
) => {
  Logger.log('DELETE external radiologist api call');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, { method: 'DELETE' })
    .then(r => {
      Logger.log('DELETE external radiologist api call response: ', r);
      return r;
    });
};
