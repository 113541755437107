import AuthService from '../index';
import Logger from '../../utils/logger';
import { jsonToProvider, providerToJson } from '../transformers';

import type {
  ApiService,
  MultipleProvidersResponse,
  User
} from '../../schemas';

const API_SERVICE: ApiService = 'telerad-settings';
const BASE_ROUTE = '/providers';

export const getProvidersApiCall = async (profile: User, sort: string) => {
  let route = BASE_ROUTE;
  if (sort.length > 0) {
    route += `?sort=${sort}`;
  }
  Logger.log('GET providers api call with route: ', route);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, route, {})
    .then((response: MultipleProvidersResponse) => {
      Logger.log('GET providers api response: ', response.providers);
      return response.providers.map(p => jsonToProvider(p));
    });
};

export const getProviderByIdApiCall = async (profile: User, id: number) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {})
    .then(provider => jsonToProvider(provider));
};

export const createProviderApiCall = async (profile: User, provider) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, BASE_ROUTE, {
    method: 'POST',
    body: providerToJson(provider)
  });
};

export const deleteProviderApiCall = async (profile: User, id: number) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
    method: 'DELETE'
  });
};

export const requestProvidersServiceApiCall = async (
  profile,
  providers,
  modalities
) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, `/request-provider-service`, {
    method: 'POST',
    body: JSON.stringify({ providers, modalities })
  });
};
