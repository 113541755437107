import React from 'react';
import { Layout } from 'antd';
import SiderMenu from './Menu';
import './Sider.less';


const Sider = ({ menus, isMobile, collapsed, onCollapseChange }) => (
  <Layout.Sider
    width={240}
    theme='dark'
    breakpoint='lg'
    trigger={null}
    collapsible
    collapsed={collapsed}
    onBreakpoint={isMobile ? null : onCollapseChange}
    className='sider'
  >
    <div className='menuContainer'>
      <SiderMenu
        menus={menus}
        isMobile={isMobile}
        collapsed={collapsed}
        onCollapseChange={onCollapseChange}
      />
    </div>
  </Layout.Sider>
);

export default Sider;
