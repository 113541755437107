import initialState from '../state';
import * as actions from '../actions/paymentsActions';

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_STRIPE_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case actions.GET_STRIPE_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload
      };
    case actions.RESTORE_STRIPE_INVOICES:
      return {
        ...state,
        invoices: {
          data: [],
          hasMore: false
        }
      };
    default:
      return state;
  }
};
