// @flow

import * as React from 'react';
import { Menu, Icon } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import store from 'store';
import { connect } from 'react-redux';

import { arrayToTree, queryAncestors, pathMatchRegexp } from '../../utils';
import icons from './icons';

import './Menu.less';
import { getStripeSessionAction } from '../../store/actions';

const { SubMenu } = Menu;

export default withRouter(
  connect(
    null, 
    { getStripeSessionAction }
  )(class SiderMenu extends React.PureComponent {
    state = {
      openKeys: store.get('openKeys') || []
    };

    onOpenChange = openKeys => {
      const { menus } = this.props;
      const rootSubmenuKeys = menus.filter(_ => !_.menuParentId).map(_ => _.id);

      const latestOpenKey = openKeys.find(
        key => this.state.openKeys.indexOf(key) === -1
      );

      let newOpenKeys = openKeys;
      if (rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
        newOpenKeys = latestOpenKey ? [latestOpenKey] : [];
      }

      this.setState({
        openKeys: newOpenKeys
      });
      store.set('openKeys', newOpenKeys);
    };

    handleClickManageSubscription = () => {
      this.props.getStripeSessionAction()
    }

    generateMenu = ({ item, menuTitle }) => {
      if (item.name === 'Support') {
        return (
          <a href={item.route} target='_blank' rel='noopener noreferrer'>
            {menuTitle}
          </a>
        )
      }
      if (item.name === 'Manage Subscription') {
        return (
          <a onClick={this.handleClickManageSubscription}>
            {menuTitle}
          </a>
        )
      }
      return <Link to={item.route || '#'}>{menuTitle}</Link>
    }

    generateMenus = data => {
      return data.map(item => {
        const menuTitle = (
          <>
            {item.icon && icons[item.icon] && (
              <Icon component={icons[item.icon]} />
            )}
            {item.icon && !icons[item.icon] && <Icon type={item.icon} />}
            <span>{item.name}</span>
          </>
        );

        if (item.children) {
          return (
            <SubMenu key={item.id} title={menuTitle}>
              {this.generateMenus(item.children)}
            </SubMenu>
          );
        }

        return (
          <Menu.Item key={item.id}>
            {this.generateMenu({ item, menuTitle })}
          </Menu.Item>
        );
      });
    };

    generateUpperMenus = data => {
      return this.generateMenus(
        data.filter(
          item =>
            item.id !== '6' &&
            item.id !== '7' &&
            item.id !== '311' &&
            item.id !== '10'
        )
      );
    };

    generateLowerMenus = data => {
      return this.generateMenus(
        data.filter(item => item.id === '6' || item.id === '7')
      );
    };

    render() {
      const {
        collapsed,
        menus,
        location,
        isMobile,
        onCollapseChange
      } = this.props;

      const { openKeys } = this.state;

      // Generating tree-structured data for menu content.
      const menuTree = arrayToTree(menus, 'id', 'menuParentId');

      // Find a menu that matches the pathname.
      const currentMenu = menus.find(
        _ => _.route && pathMatchRegexp(_.route, location.pathname)
      );

      // Find the key that should be selected according to the current menu.
      const selectedKeys = currentMenu
        ? queryAncestors(menus, currentMenu, 'menuParentId').map(_ => _.id)
        : [];

      const menuProps = collapsed ? {} : { openKeys };

      return (
        <>
          <Menu
            mode='inline'
            theme='dark'
            onOpenChange={this.onOpenChange}
            selectedKeys={selectedKeys}
            onClick={
              isMobile
                ? () => {
                    onCollapseChange(true);
                  }
                : undefined
            }
            {...menuProps}
          >
            {this.generateUpperMenus(menuTree)}
          </Menu>
          <div className='menu-bottom'>
            {!collapsed ? (
              <div className='connection-wrapper'>
                <a
                  href='https://connect.alem.health'
                  target='_blank'
                  className='connection'
                  rel='noopener noreferrer'
                >
                  <Icon type='plus' style={{ paddingRight: '8px' }} />
                  AlemHealth Connect
                </a>
              </div>
            ) : null}

            <Menu
              mode='inline'
              theme='dark'
              onOpenChange={this.onOpenChange}
              selectedKeys={selectedKeys}
              onClick={
                isMobile
                  ? () => {
                      onCollapseChange(true);
                    }
                  : undefined
              }
              {...menuProps}
            >
              {this.generateLowerMenus(menuTree)}
            </Menu>
          </div>
        </>
      );
    }
  })
);
