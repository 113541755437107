import * as actions from '../actions/profileActions';
import initialState from '../state';

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCEED:
      // eslint-disable-next-line no-console
      return {
        ...state,
        name: action.data.name,
        accessToken: action.data.accessToken,
        idToken: action.data.idToken,
        expiresAt: action.data.expiresAt,
        email: action.data.email,
        avatar: action.data.avatar,
        id: action.data.id,
        logins_count: action.data.logins_count,
        userMetadata: action.data.userMetadata,
        appMetadata: action.data.appMetadata
      };
    case actions.LOGOUT:
      return {
        appMetadata: {
          admin: '0'
        },
        userMetadata: {
          userType: ''
        }
      };
    case actions.ACCOUNT_SETTINGS_REQUEST:
      return state;
    case actions.ACCOUNT_SETTINGS_SUCCESS:
      // eslint-disable-next-line no-alert
      // eslint-disable-next-line no-undef
      return { ...state, ...action.userUpdate };
    case actions.ACCOUNT_SETTINGS_FAILED:
      return state;
    case actions.NOTIFICATIONS_SETTINGS_REQUEST:
      return state;
    case actions.NOTIFICATIONS_SETTINGS_SUCCESS:
      return { ...state, user: action.settings };
    case actions.NOTIFICATIONS_SETTINGS_FAILED:
      return state;
    case actions.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.data]
      };
    case actions.READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: []
      };
    case actions.CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          not => not.id !== action.notification_id
        )
      };
    case actions.GET_AZURE_ADDRESS_SUCCESS:
      return {
        ...state,
        azure: action.address
      };
    case actions.GET_USER_STUDY_COUNT_SUCCESS:
      return {
        ...state,
        studyCount: action.studyCount
      };
    default:
      return state;
  }
};
