/* eslint-disable no-undef */
/* eslint-disable no-alert */
import { put, call, select, takeLatest, take } from 'redux-saga/effects';
import {
  GET_ACTIVE_STUDIES_REQUEST,
  GET_ACTIVE_STUDIES_SUCCESS,
  GET_ACTIVE_STUDIES_FAILURE,
  GET_GROUP_DETAIL_REQUEST,
  GET_GROUP_DETAIL_SUCCESS,
  GET_GROUP_DETAIL_FAILURE,
  GET_FACILITIES_REQUEST,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILURE,
  GET_FACILITIES_STATUS_SUCCESS,
  GET_OVERVIEW_REQUEST,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_FAILURE,
  GET_STUDY_DETAILS_REQUEST,
  GET_STUDY_DETAILS_SUCCESS,
  GET_STUDY_DETAILS_FAILURE,
  GET_RADIOLOGIST_PERFORMANCE_REQUEST,
  GET_RADIOLOGIST_PERFORMANCE_SUCCESS,
  GET_RADIOLOGIST_PERFORMANCE_FAILURE,
  GET_PROVIDER_PERFORMANCE_REQUEST,
  GET_PROVIDER_PERFORMANCE_SUCCESS,
  GET_PROVIDER_PERFORMANCE_FAILURE,
  GET_MODALITIES_REQUEST,
  GET_MODALITIES_SUCCESS,
  GET_MODALITIES_FAILURE,
  GET_REPORTING_RADIOLOGISTS_REQUEST,
  GET_REPORTING_RADIOLOGISTS_SUCCESS,
  GET_REPORTING_RADIOLOGISTS_FAILURE,
  GET_SENT_TOS_REQUEST,
  GET_SENT_TOS_SUCCESS,
  GET_SENT_TOS_FAILURE,
  dateFilterStrings
} from '../constants';
import {
  GET_ACTIVE_STUDIES_LOADING,
  GET_GROUP_DETAIL_LOADING,
  GET_FACILITIES_LOADING,
  GET_OVERVIEW_LOADING,
  GET_RADIOLOGIST_PERFORMANCE_LOADING,
  GET_PROVIDER_PERFORMANCE_LOADING,
  GET_STUDY_DETAILS_LOADING
} from '../actions';
import { showError, getFormValues, getQuery } from '../helpers';
import {
  getActiveStudiesApiCall,
  getMyFacilitiesApiCall,
  getMyFacilitiesStatusApiCall,
  getGroupDetailApiCall,
  getOverviewApiCall,
  getStudyDetailsApiCall,
  getRadiologistPerformanceApiCall,
  getProviderPerformanceApiCall,
  getModalitiesApiCall,
  getReportingRadiologistApiCall,
  getSentTosApiCall
} from '../../api';
import {
  STUDY_DETAILS_CARD_FORM_NAME,
  PROVIDER_PERFORMANCE_CARD_FORM_NAME,
  RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME
} from '../../utils/form-helpers';
import { getCurrentUser } from '../selectors';

function* getModalitiesSaga(action) {
  try {
    const profile = yield select(getCurrentUser);
    const radiologistPerformance = yield call(getModalitiesApiCall, profile);
    yield put({
      type: GET_MODALITIES_SUCCESS,
      data: radiologistPerformance
    });
  } catch (error) {
    yield put({ type: GET_MODALITIES_FAILURE, error });
    yield call(showError, error);
  }
}

function* getReportingRadiologistSaga(action) {
  try {
    const profile = yield select(getCurrentUser);
    const radiologistPerformance = yield call(
      getReportingRadiologistApiCall,
      profile
    );
    yield put({
      type: GET_REPORTING_RADIOLOGISTS_SUCCESS,
      data: radiologistPerformance
    });
  } catch (error) {
    yield put({ type: GET_REPORTING_RADIOLOGISTS_FAILURE, error });
    yield call(showError, error);
  }
}

function* getSentTosSaga(action) {
  try {
    const profile = yield select(getCurrentUser);
    const radiologistPerformance = yield call(getSentTosApiCall, profile);
    yield put({
      type: GET_SENT_TOS_SUCCESS,
      data: radiologistPerformance
    });
  } catch (error) {
    yield put({ type: GET_SENT_TOS_FAILURE, error });
    yield call(showError, error);
  }
}

function* getActiveStudiesSaga(action) {
  yield put({ type: GET_ACTIVE_STUDIES_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);

    const activeStudies = yield call(getActiveStudiesApiCall, profile);

    yield put({
      type: GET_ACTIVE_STUDIES_SUCCESS,
      data: activeStudies
    });
  } catch (error) {
    yield put({ type: GET_ACTIVE_STUDIES_FAILURE, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_ACTIVE_STUDIES_LOADING, loading: false });
  }
}

function* getGroupDetailSaga(action) {
  yield put({ type: GET_GROUP_DETAIL_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const groupDetail = yield call(getGroupDetailApiCall, profile);
    yield put({
      type: GET_GROUP_DETAIL_SUCCESS,
      data: groupDetail
    });
  } catch (error) {
    yield put({ type: GET_GROUP_DETAIL_FAILURE, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_GROUP_DETAIL_LOADING, loading: false });
  }
}

function* getFacilitiesSaga() {
  yield put({ type: GET_FACILITIES_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const facilities = yield call(getMyFacilitiesApiCall, profile);
    yield put({
      type: GET_FACILITIES_SUCCESS,
      data: facilities
    });
    yield put({ type: GET_FACILITIES_LOADING, loading: false });

    // const facilitiesStatusHistory = yield call(
    //   getMyFacilitiesStatusHistoryApiCall,
    //   profile
    // );

    // if (facilitiesStatusHistory && facilitiesStatusHistory.length > 0) {
    //   yield all(
    //     facilitiesStatusHistory.map(facilityStatus => {
    //       return put({
    //         type: GET_FACILITIES_STATUS_SUCCESS,
    //         data: facilityStatus.message
    //       });
    //     })
    //   );
    // }

    const channel = yield call(getMyFacilitiesStatusApiCall, profile);
    while (true) {
      const action = yield take(channel);
      if (action.message) {
        yield put({
          type: GET_FACILITIES_STATUS_SUCCESS,
          data: action.message
        });
      } else if (action.occupants && action.occupants.length > 0) {
        const onlineFacilities = action.occupants.map(item => {
          if (item.state) {
            return { ...item.state, facilityId: item.uuid };
          }
          return null;
        });
        yield put({
          type: GET_FACILITIES_STATUS_SUCCESS,
          data: onlineFacilities
        });
      }
    }
  } catch (err) {
    yield put({ type: GET_FACILITIES_FAILURE, err });
    yield call(showError, err);
  } finally {
    yield put({ type: GET_FACILITIES_LOADING, loading: false });
  }
}

function* getOverviewSaga(action) {
  yield put({ type: GET_OVERVIEW_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const filters = yield select(getFormValues, OVERVIEW_CARD_FORM_NAME);
    const { dateFilter } = filters;
    const overview = yield call(getOverviewApiCall, profile, dateFilter);
    yield put({
      type: GET_OVERVIEW_SUCCESS,
      data: overview
    });
  } catch (error) {
    yield put({ type: GET_OVERVIEW_FAILURE, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_OVERVIEW_LOADING, loading: false });
  }
}

function* getStudyDetailsSaga(action) {
  yield put({ type: GET_STUDY_DETAILS_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const filters = yield select(getFormValues, STUDY_DETAILS_CARD_FORM_NAME);
    const { selectedDate } = filters;
    const timeFrame = action.timeFrame
      ? action.timeFrame
      : dateFilterStrings.TODAY;
    const studyDetails = yield call(
      getStudyDetailsApiCall,
      profile,
      timeFrame,
      selectedDate
    );
    yield put({
      type: GET_STUDY_DETAILS_SUCCESS,
      data: studyDetails
    });
  } catch (error) {
    yield put({ type: GET_STUDY_DETAILS_FAILURE, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_STUDY_DETAILS_LOADING, loading: false });
  }
}

function* getProviderPerformanceSaga(action) {
  yield put({ type: GET_PROVIDER_PERFORMANCE_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const filters = yield select(
      getFormValues,
      PROVIDER_PERFORMANCE_CARD_FORM_NAME
    );
    const query = getQuery(filters);
    const providerPerformance = yield call(
      getProviderPerformanceApiCall,
      profile,
      query
    );
    yield put({
      type: GET_PROVIDER_PERFORMANCE_SUCCESS,
      data: providerPerformance
    });
  } catch (error) {
    yield put({ type: GET_PROVIDER_PERFORMANCE_FAILURE, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_PROVIDER_PERFORMANCE_LOADING, loading: false });
  }
}

function* getRadiologistPerformanceSaga(action) {
  yield put({ type: GET_RADIOLOGIST_PERFORMANCE_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const filters = yield select(
      getFormValues,
      RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME
    );
    const { selectedDate } = filters || {};
    if (selectedDate) {
      const timeFrame = action.timeFrame
        ? action.timeFrame
        : dateFilterStrings.TODAY;
      const radiologistPerformance = yield call(
        getRadiologistPerformanceApiCall,
        profile,
        timeFrame,
        selectedDate
      );
      yield put({
        type: GET_RADIOLOGIST_PERFORMANCE_SUCCESS,
        data: radiologistPerformance
      });
    }
  } catch (error) {
    yield put({ type: GET_RADIOLOGIST_PERFORMANCE_FAILURE, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_RADIOLOGIST_PERFORMANCE_LOADING, loading: false });
  }
}

export default function* dashboardSaga() {
  yield takeLatest(GET_MODALITIES_REQUEST, getModalitiesSaga);
  yield takeLatest(
    GET_REPORTING_RADIOLOGISTS_REQUEST,
    getReportingRadiologistSaga
  );
  yield takeLatest(GET_SENT_TOS_REQUEST, getSentTosSaga);
  yield takeLatest(GET_ACTIVE_STUDIES_REQUEST, getActiveStudiesSaga);
  yield takeLatest(GET_GROUP_DETAIL_REQUEST, getGroupDetailSaga);
  yield takeLatest(GET_FACILITIES_REQUEST, getFacilitiesSaga);
  yield takeLatest(GET_OVERVIEW_REQUEST, getOverviewSaga);
  yield takeLatest(GET_STUDY_DETAILS_REQUEST, getStudyDetailsSaga);
  yield takeLatest(
    GET_RADIOLOGIST_PERFORMANCE_REQUEST,
    getRadiologistPerformanceSaga
  );
  yield takeLatest(
    GET_PROVIDER_PERFORMANCE_REQUEST,
    getProviderPerformanceSaga
  );
}
