/* eslint-disable spaced-comment */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
// @flow

/**
 * Summary
 * The dashboard endpoint retrieves data that available for dashboard.
 */

import PubNub from 'pubnub';
import { eventChannel } from 'redux-saga';
import moment from 'moment';
import AuthService from '../index';
import Logger from '../../utils/logger';
import {
  jsonToActiveStudy,
  jsonToFacility,
  jsonToGroup,
  jsonToRadiologistPerformance,
  jsonToStudyDetails
} from '../transformers';
import type { UserProfile, ApiService } from '../../schemas';
import Configuration from '../../utils/config';
import { dateFilterStrings } from '../../store/constants';

const API_SERVICE: ApiService = 'telerad-settings';
const API_FACILITY_SERVICE: ApiService = 'facilities';
const BASE_ROUTE = '/dashboard';

const pubnub = new PubNub({
  subscribeKey: Configuration.pubNub.subscriptionKey,
  logVerbosity: true,
  ssl: true
});

/**
 * Get the modalities available for the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a list of modality
 */
export const getModalitiesApiCall = async (profile: UserProfile) => {
  return [
    {
      id: '1',
      name: 'CR'
    },
    {
      id: '2',
      name: 'CT'
    },
    {
      id: '3',
      name: 'DX'
    },
    {
      id: '4',
      name: 'EC'
    },
    {
      id: '5',
      name: 'MG'
    },
    {
      id: '6',
      name: 'MR'
    },
    {
      id: '7',
      name: 'NM'
    },
    {
      id: '8',
      name: 'OP'
    },
    {
      id: '9',
      name: 'OT'
    },
    {
      id: '10',
      name: 'PT'
    },
    {
      id: '11',
      name: 'RF'
    },
    {
      id: '12',
      name: 'US'
    },
    {
      id: '13',
      name: 'XA'
    }
  ];
};

/**
 * Get the reporting radiologists available for the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a list of reporting radiologist
 */
export const getReportingRadiologistApiCall = async (profile: UserProfile) => {
  const route = `${BASE_ROUTE}/reporting-radiologist-options`;

  Logger.log('GET reporting radiologists api call with route: ', route);

  return {};
};

/**
 * Get the sent tos available for the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a list of sent to
 */
export const getSentTosApiCall = async (profile: UserProfile) => {
  const route = `${BASE_ROUTE}/sent-to-options`;

  Logger.log('GET sent tos api call with route: ', route);

  return {};
};

/**
 * Get the active studies available for the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a list of active study
 */
export const getActiveStudiesApiCall = async (profile: UserProfile) => {
  const route = `/active-studies/`;

  Logger.log('GET active studies api call with route: ', route);

  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service('dashboard', route, {}).then(response => {
    Logger.log('GET active studies api response: ', response);
    return {
      list: response.results.map(r => jsonToActiveStudy(r)),
      total: response.total
    };
  });
};

/**
 * Get the facilities available for the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a list of active study
 */
export const getMyFacilitiesApiCall = async (profile: UserProfile) => {
  Logger.log('Get my facilities called');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_FACILITY_SERVICE, '/facilities/ingroup/', {})
    .then((r: { facilities: Array<Object> }) =>
      r.facilities.map(jsonToFacility)
    );
};

/**
 * Get the facilities status available for a facility in the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a status of a facility
 */
export const getMyFacilitiesStatusApiCall = (profile: UserProfile) => {
  pubnub.setUUID(profile.id);
  const channel = profile.appMetadata.affiliatedGroup;

  return eventChannel(emit => {
    pubnub.addListener({
      message: messageEvent => {
        Logger.log('New my facilities status received');
        //emit(messageEvent);
      },
      presence: presenceEvent => {
        pubnub.hereNow(
          {
            channels: [channel],
            includeUUIDs: true,
            includeState: true
          },
          (status, response) => {
            if (response) {
              Logger.log('New my facilities status received');
              emit(response.channels[channel]);
            }
          }
        );
      }
    });

    pubnub.subscribe({
      channels: [channel],
      withPresence: true
    });

    return () => {
      pubnub.unsubscribe({
        channels: [channel]
      });
    };
  });
};

export const getMyFacilitiesStatusHistoryApiCall = (profile: UserProfile) => {
  pubnub.setUUID(profile.id);
  const channel = profile.appMetadata.affiliatedGroup;
  const timetoken =
    moment()
      .subtract(5, 'minutes')
      .unix() * Configuration.pubNub.pubNubTimeMultiplier;
  return new Promise<any>((resolve, reject) => {
    pubnub.fetchMessages(
      {
        channels: [channel],
        includeMessageActions: true,
        end: timetoken,
        count: 100
      },
      (status, response) => {
        try {
          if (response) {
            resolve(response.channels[channel]);
          }
        } catch (er) {
          // eslint-disable-next-line no-console
          console.error('PUBNUB : Facilities status history', er);
          resolve(er);
        }
      }
    );
  });
};

/**
 * Get the detail of the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a detail of group
 */
export const getGroupDetailApiCall = async (profile: UserProfile) => {
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, '/group', {})
    .then(g => jsonToGroup(g));
};

/**
 * Get the provider performance of the group. It accepts a series of methods to filter
 * the results.
 * @param profile: user profile
 * @param query: a query string that represents additional queries to pass to limit user results. If multiple queries
 * shall be passed, they must be separated by a plus ("+") sign. Each query follows the lucene query format (key:value)
 * For example: `modality:CT+facility:6290f1ee-6c54-4b01-90e6-d701748f0851`
 * @returns {never|Promise<Response>} A promise with an http response containing a provider performance
 */
export const getProviderPerformanceApiCall = async (
  profile: UserProfile,
  query: string
) => {
  let route = `${BASE_ROUTE}/provider-performance`;
  if (query) {
    route += `?q=${query}`;
  }
  Logger.log('GET radiologist performance api call with route: ', route);

  return {};
};

/**
 * Get the overview of the group. It accepts a series of methods to filter
 * the results.
 * @param profile: user profile
 * @param date: a string of date the overview
 * @returns {never|Promise<Response>} A promise with an http response containing an overview
 */
export const getOverviewApiCall = async (
  profile: UserProfile,
  date: string
) => {
  let route = `${BASE_ROUTE}/overview`;
  if (date) {
    route += `?date=${date}`;
  }

  Logger.log('GET overview api call with route: ', route);

  return {};
};

/**
 * Get the study details of the group. It accepts a series of methods to filter
 * the results.
 * @param profile: user profile
 * @param date: a string of date the study details
 * @returns {never|Promise<Response>} A promise with an http response containing a study details
 */
export const getStudyDetailsApiCall = async (
  profile: UserProfile,
  timeFrame: string,
  selectedDate: Array<string>
) => {
  let route = `/study-details/`;
  if (timeFrame) {
    if (timeFrame === dateFilterStrings.CUSTOM) {
      const fromDate = moment(selectedDate[0]).format('YYYY-MM-DD');
      const toDate = moment(selectedDate[1]).format('YYYY-MM-DD');
      route += `?timeframe=${timeFrame}&from_date=${fromDate}&to_date=${toDate}`;
    } else {
      route += `?timeframe=${timeFrame}`;
    }
  }
  Logger.log('GET study details api call with route: ', route);

  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service('dashboard', route, {}).then(response => {
    Logger.log('GET study details api response: ', response);

    const studyDetails = jsonToStudyDetails(response);
    return studyDetails;
  });
};

/**
 * Get the radiologist performance of the group. It accepts a series of methods to filter
 * the results.
 * @param profile: user profile
 * @param date: a string of date the radilogist performance
 * @returns {never|Promise<Response>} A promise with an http response containing an radiologist performance
 */

export const getRadiologistPerformanceApiCall = async (
  profile: UserProfile,
  timeFrame: string,
  selectedDate: Array<string>
) => {
  let route = `/signed-reports/`;
  if (timeFrame) {
    if (timeFrame === dateFilterStrings.CUSTOM) {
      const fromDate = moment(selectedDate[0]).format('YYYY-MM-DD');
      const toDate = moment(selectedDate[1]).format('YYYY-MM-DD');
      route += `?timeframe=${timeFrame}&from_date=${fromDate}&to_date=${toDate}`;
    } else {
      route += `?timeframe=${timeFrame}`;
    }
  }

  Logger.log('GET adiologist performance api call with route: ', route);

  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service('dashboard', route, {}).then(response => {
    Logger.log('GET radiologist performance response: ', response);

    return response.signed_reports.map(r => jsonToRadiologistPerformance(r));
  });
};
