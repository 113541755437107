import React from 'react';
import { Modal, InputNumber, Typography, Row, Col, Button } from 'antd';

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: 14,
    marginTop: 14
  }
};

const { Title } = Typography;

type Props = {
  visible: Boolean,
  isLoading: Boolean,
  checkout: Function,
  setNumberOfAlemBox: Function,
  setNumberOfAlemHub: Function,
  setVisibility: boolean => void,
  name: string
};

export default ({
  visible,
  isLoading,
  setVisibility,
  checkout,
  setNumberOfAlemBox,
  setNumberOfAlemHub,
  name
}: Props) => {
  return (
    <Modal
      visible={visible}
      title='Ordering AlemHealth Hardware'
      okText='Submit'
      footer={[
        <Button key='back' onClick={() => setVisibility(false)}>
          Cancel
        </Button>,
        <Button
          key='submit'
          type='primary'
          loading={isLoading}
          onClick={checkout}
        >
          Submit
        </Button>
      ]}
    >
      <div className='payments-modal'>
        <Row justify='start' align='middle' gutter={[24, 0]}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Title level={4} style={styles.title}>
              In how many facilities would you like to install AlemBox devices?
            </Title>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <InputNumber
              min={1}
              max={100000}
              defaultValue={1}
              onChange={value => setNumberOfAlemBox(value)}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <Row justify='start' align='middle' gutter={[24, 0]}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Title level={4} style={styles.title}>
              In how many facilities would you like to install AlemHub devices?
            </Title>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <InputNumber
              min={1}
              max={100000}
              defaultValue={1}
              onChange={value => setNumberOfAlemHub(value)}
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <Row justify='start' align='middle' gutter={[24, 0]}>
          <Col span={24} style={{ textAlign: 'left' }}>
            <Title level={4} style={styles.title}>
              After you click “Submit” one of our implementation engineers will
              get in touch with you to set up your devices prior to shipment.
            </Title>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
