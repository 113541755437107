// import { checkSession } from './lock';
import Logger from '../../utils/logger';
import Configuration from '../../utils/config';
import { removeUUID } from '../../utils';

export const checkStatus = async response => {
  Logger.log('Response status code ', response.status);
  if (response.status >= 200 && response.status < 300) {
    // Success status lies between 200 to 300
    return response;
  }
  if (response.status === 401) {
    throw new Error('Please log back in to your account.');
  }
  if (response.status === 500) {
    throw new Error('Internal server error.');
  }
  const responseText = await response.text();
  const responseJson = JSON.parse(responseText);
  Logger.log('Response text ', responseText);
  let errorMessage = `${response.status}${
    response.statusText ? `- ${response.statusText}` : ''
  }: `;
  // eslint-disable-next-line no-console
  console.error(`Error: ${response.status}`, responseText);
  Object.keys(response).forEach(prop => (errorMessage += ` ${response[prop]}`));
  if (responseJson.error) errorMessage += removeUUID(responseJson.error);
  if (responseJson.message) errorMessage += removeUUID(responseJson.message);
  if (responseJson.msg) errorMessage += removeUUID(responseJson.msg);
  throw new Error(errorMessage);
};

export default class Auth0Service {
  profile;

  constructor(profile) {
    this.profile = profile;
  }

  service = async (api, route, options) => {
    // await checkSession();
    const { accessToken } = this.profile;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Ocp-Apim-Subscription-Key': Configuration.api.subscriptionKey
    };

    let apiUrl = Configuration.api.teleradSettingsAddress;
    if (api === 'facilities') {
      apiUrl = Configuration.api.facilitiesAddress;
    } else if (api === 'physicians') {
      apiUrl = Configuration.api.physiciansAddress;
    } else if (api === 'dashboard') {
      apiUrl = Configuration.api.dashboardAddress;
    } else if (api === 'base') {
      apiUrl = Configuration.api.baseAddress;
    } else if (api === 'embed') {
      apiUrl = Configuration.api.embedAddress;
    } else if (api === 'devices') {
      apiUrl = Configuration.api.devicesAddress;
    } else if (api === 'signup-invite-list') {
      apiUrl = Configuration.api.signupInvite;
    } else if (api === 'referring') {
      apiUrl = Configuration.api.referringAddress;
    }

    Logger.log('Making api request to: ', `${apiUrl}${route}`);
    return fetch(`${apiUrl}${route}`, {
      headers,
      ...options
    })
      .then(checkStatus)
      .then(response => response.json());
  };
}
