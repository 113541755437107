/* eslint-disable camelcase */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { change } from 'redux-form';
import uuid from 'uuid/v4';
import {
  AnonymousCredential,
  ContainerURL,
  ServiceURL,
  StorageURL
} from '@azure/storage-blob';
import { v4 as uuidV4 } from 'uuid';
import { getFormValues, showError, showSuccess } from '../helpers';
import * as actions from '../actions';
import { ONBOARDING_FORM } from '../../utils/form-helpers';
import { uploadFile } from '../../utils/azure-blob-helpers';
import decrypt from '../../utils/crypto';
import {
  getAffiliatedGroup,
  getCurrentUser,
  getFacilities
} from '../selectors';
import {
  createFacility,
  inviteColleaguesApiCall,
  inviteRadiologistApiCall,
  updateGroupAPiCall,
  updateSecondOpinionSettingApiCall,
  updateUserApiCall
} from '../../api';
import { createPhysicianApiCall } from '../../api/endpoints/physicians';
import { getAzureAddressApiCall } from '../../api/endpoints/options';
import {
  showNotificationForUploadInProgress,
  showNotificationForUploadFailed
} from '../../utils/notification-helpers';
import { postInviteToZapierAPICall } from '../../api/endpoints/zapier';

function* inviteRadiologistsHandler(action) {
  yield put({ type: actions.ONBOARDING_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const group = yield select(getAffiliatedGroup);
    const facilities = yield select(getFacilities);

    const { radiologists } = action;
    yield all(
      radiologists.map(item => {
        const radiologist = {
          name: item.name,
          email: item.email,
          userMetadata: {
            name: item.name,
            homeFacility: '',
            phoneNumber: item.phoneNumber,
            specialty: item.specialty,
            userType: item.userType,
            title: item.title,
            credentials: item.credentials
          },
          appMetadata: {
            affiliatedGroup: group,
            approvedFacilities: facilities
              .filter(
                f =>
                  item.groupFacilities && item.groupFacilities.includes(f.name)
              )
              .map(f => f.guid),
            admin: '0',
            main: '0'
          }
        };
        return all([
          call(postInviteToZapierAPICall, {
            jsonContent: {
              inviter_name: profile.name,
              inviter_email: profile.email,
              organization_guid: group,
              invitee_name: item.name,
              invitee_emails: item.email,
              invitee_role: "Radiologist",
              access_level: item.userType
            }
          }),
          call(inviteRadiologistApiCall, profile, radiologist)
        ]);
      })
    );
    yield call(showSuccess, 'Invite radiologists success');
    yield put({ type: actions.ONBOARDING_LOADING, loading: false });
    yield put(actions.updateOnboardingFormRequest());
  } catch (error) {
    yield call(showError, error);
  } finally {
    yield put({ type: actions.ONBOARDING_LOADING, loading: false });
  }
}

function* inviteColleaguesHandler(action) {
  yield put({ type: actions.ONBOARDING_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const requestBody = {
      inviter_name: profile.name,
      invitees: action.users
    };
    yield call(inviteColleaguesApiCall, profile, requestBody);
    yield call(showSuccess, 'Invite colleagues success');
    yield put(actions.updateOnboardingFormRequest());
  } catch (error) {
    yield call(showError, error);
  } finally {
    yield put({ type: actions.ONBOARDING_LOADING, loading: false });
  }
}

function* addFacilitiesHandler(action) {
  yield put({ type: actions.ONBOARDING_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const group = yield select(getAffiliatedGroup);
    const homeFacilities = yield select(getFacilities);
    const { facilities } = action;
    const allRequestData = [];
    facilities.forEach(item => {
      const { name, email, type } = item;

      const requestData = {
        name,
        email,
        facility_type: type,
        member_of: group
      };

      allRequestData.push(requestData);
    });

    const responses = yield all(
      allRequestData.map(requestData =>
        call(createFacility, profile, requestData)
      )
    );

    const approvedFacilities = homeFacilities.map(f => f.guid);
    if (responses && responses.length > 0) {
      responses.forEach(f => {
        approvedFacilities.push(f && f.guid);
      });
    }
    const userUpdate = {
      ...profile,
      appMetadata: { ...profile.appMetadata, approvedFacilities }
    };
    yield call(updateUserApiCall, profile, profile.id, userUpdate);

    yield call(showSuccess, 'Add facilities success');
    yield put(actions.updateOnboardingFormRequest());
  } catch (error) {
    yield call(showError, error);
  } finally {
    yield put({ type: actions.ONBOARDING_LOADING, loading: false });
  }
}

function* onboardingFormHandler() {
  try {
    const onBoardingForm = yield select(getFormValues, ONBOARDING_FORM);

    const { selectedId } = onBoardingForm;
    yield put(change(ONBOARDING_FORM, 'selectedId', selectedId + 1));
    yield put(
      change(
        ONBOARDING_FORM,
        `onboardingSteps.${[selectedId - 1]}.isDone`,
        true
      )
    );
  } catch (error) {
    // yield put({ type: actions.ONBOARDING_LOADING, loading: false });
    yield call(showError, error);
  } finally {
    // yield put({ type: actions.ONBOARDING_LOADING, loading: false });
  }
}

function* addPhysiciansHandler(action) {
  yield put({ type: actions.ONBOARDING_LOADING, loading: true });
  try {
    const guid = yield call(uuid);
    const profile = yield select(getCurrentUser);
    const { physicians } = action;
    yield all(
      physicians.map(item => {
        return call(createPhysicianApiCall, profile, {
          ...item,
          guid,
          refersTo: profile.appMetadata.affiliatedGroup
        });
      })
    );
    yield call(showSuccess, 'Add reffering physician success');
    yield put(actions.updateOnboardingFormRequest());
  } catch (error) {
    yield call(showError, error);
  } finally {
    yield put({ type: actions.ONBOARDING_LOADING, loading: false });
  }
}

export function* updateSecondOpinionSettingHandler(action) {
  const profile = yield select(getCurrentUser);
  yield put({ type: actions.ONBOARDING_LOADING, loading: true });

  try {
    const data = { ...action.secondOpinionSetting };
    const { logo_file_location } = data;
    const requestBody = { ...data };
    if (logo_file_location && typeof logo_file_location !== 'string') {
      const file = logo_file_location;
      const notification = {
        notificationKey: 'upload',
        message: 'Uploader Logo Upload',
        content: 'Uploading Image file'
      };
      const containerName = 'grouplogos';
      const azureAddress = yield call(getAzureAddressApiCall, profile);
      const anonymousCredential = new AnonymousCredential();
      const pipeline = StorageURL.newPipeline(anonymousCredential);
      const serviceURL = new ServiceURL(
        decrypt(profile.accessToken, azureAddress),
        pipeline
      );
      const containerURL = ContainerURL.fromServiceURL(
        serviceURL,
        containerName
      );

      const fileName = `secondopinionbranding-${uuidV4()}.${
        file.name.split('.', -1)[1]
      }`;

      const response = yield call(uploadFile, {
        containerURL,
        file,
        fileName,
        showNotificationForUploadInProgress,
        showNotificationForUploadFailed,
        notification
      });
      if (response) {
        requestBody.logo_file_location = `https://teleradsettings.blob.core.windows.net/${containerName}/${fileName}`;
      }
    }

    const secondOpinionSetting = yield call(
      updateSecondOpinionSettingApiCall,
      profile,
      requestBody
    );

    if (
      data.location ||
      data.officeNumber ||
      data.emailAddress ||
      data.website
    ) {
      const groupData = {
        location: data.location,
        officeNumber: data.officeNumber,
        emailAddress: data.emailAddress,
        website: data.website
      };
      yield call(updateGroupAPiCall, profile, groupData);
      yield call(showSuccess, 'Branding settings updated.');
    } else {
      yield call(showSuccess, 'Web Uploader Activated');
    }
    yield put(actions.updateOnboardingFormRequest());
    yield put({
      type: actions.UPDATE_SECOND_OPINION_SETTING_SUCCESS,
      secondOpinionSetting
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_SECOND_OPINION_SETTING_FAILURE, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.ONBOARDING_LOADING, loading: false });
  }
}

export default function* onboardingSaga() {
  yield takeLatest(
    actions.INVITE_ONBOARDING_RADIOLOGISTS_REQUEST,
    inviteRadiologistsHandler
  );

  yield takeLatest(
    actions.INVITE_ONBOARDING_COLLEAGUES_REQUEST,
    inviteColleaguesHandler
  );

  yield takeLatest(actions.UPDATE_ONBOARDING_FORM, onboardingFormHandler);

  yield takeLatest(
    actions.ADD_ONBOARDING_FACILITIES_REQUEST,
    addFacilitiesHandler
  );

  yield takeLatest(
    actions.ADD_ONBOARDING_PHYSICIANS_REQUEST,
    addPhysiciansHandler
  );

  yield takeLatest(
    actions.UPDATE_ONBOARDING_SECOND_OPINION_REQUEST,
    updateSecondOpinionSettingHandler
  );
}
