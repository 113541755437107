/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// @flow

import * as React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import debounce from 'lodash/debounce';
import PublicLayout from './PublicLayout';
import PrimaryLayout from './PrimaryLayout';
import { config } from '../utils';

import { checkAuthenticated } from '../api';
import { getLoginData, loginSucceed } from '../store/actions';

import { ReduxState } from '../schemas';

import './BaseLayout.less';

export default withRouter(
  connect(
    (state) => ({
      loading: state.loading,
      token: state.profile.accessToken,
      admin: state.profile.appMetadata.admin,
      userType: state.profile.userMetadata.userType,
      logins_count: state.profile.logins_count
    }),
    {
      loginRequest: getLoginData,
      loginSuccess: loginSucceed
    }
  )(withTranslation()(
    class BaseLayout extends React.PureComponent<Props, State> {
      previousPath = '';

      state = {
        previousLocation: ''
      };

      renderLayout = () => {
        const {
          token,
          history,
          children,
          location,
          loginRequest,
          loginSuccess,
          admin,
          logins_count,
          userType
        } = this.props;

        // If we have no token, we find it in local storage.
        if (!token) {
          loginRequest();
        }

        // Check authenticated checks that we have a valid token and, if the token is next to expire,
        // it calls the update method on the auth object to renew it.

        if (checkAuthenticated(token, loginSuccess)) {
          if (
            (location.pathname.includes('login') ||
              location.pathname.includes('auth0-callback') ||
              location.pathname.length < 2 ||
              location.pathname.includes('no_admin')) &&
            admin === '1' &&
            userType !== 'independent radiologist'
          ) {
            if (logins_count < 2) {
              history.push('/onboarding');
            } else {
              history.push('/dashboard');
            }
            // history.push('/user');
          } else if (
            !location.pathname.includes('no_admin') &&
            (admin === '0' || userType === 'independent radiologist')
          ) {
            history.push('/no_admin');
          }
          if (admin === '1') {
            if (location.pathname.includes('devices') || location.pathname.includes('user')) {
              return <PrimaryLayout noTitle>{children}</PrimaryLayout>;
            }
            return <PrimaryLayout>{children}</PrimaryLayout>;
          }
          return <PublicLayout>{children}</PublicLayout>;
        }
        if (
          !location.pathname.includes('login') &&
          !(location.hash.length > 0 && location.hash.includes('#access_token'))
        ) {
          history.push('/login');
        }
        return <PublicLayout>{children}</PublicLayout>;
      };

      render() {
        const { loading, location, userType } = this.props;
        const currentPath = location.pathname + location.search;
        if (!loading.global) {
          this.previousPath = currentPath;
        }

        return (
          <React.Fragment>
            <Helmet>
              <title>{config.basic.siteName}</title>
            </Helmet>
            {this.renderLayout()}
          </React.Fragment>
        );
      }
    }
  )
));
