/* eslint-disable no-console */
import React from 'react';
import posthog from 'posthog-js';
import ReactDOM from 'react-dom';
import store from 'store';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

if (process.env.NODE_ENV === 'production') {
  posthog.init('phc_bpXBOBgbUWSwbr1KnjlIHWpnkHfZ10TLLW8GiRXzf3M');
  Sentry.init({
    dsn:
      'https://0441bde5c620457a904722f4501a9618@o373641.ingest.sentry.io/5241605',
    ignoreErrors: [
      'TypeError: Failed to fetch',
      'TypeError: NetworkError when attempting to fetch resource.',
      'TypeError: Cancelled',
      'TypeError: cancelado',
      'Failed to fetch'
    ],
    integrations: [
      new Integrations.CaptureConsole({
        levels: ['error']
      }),
      new posthog.SentryIntegration(posthog, 'alemhealth-ql', '5241605')
    ]
  });
  const user = store.get('profile');
  if (user) {
    Sentry.configureScope(function(scope) {
      scope.setTag('userEmail', user.email);
    });
    posthog.identify({
      name: user.name,
      email: user.email
    });
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
