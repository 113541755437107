import { call, put, select, takeLatest } from 'redux-saga/effects';
import { showError, showSuccess } from '../helpers';
import { RevokeAdminRightsAction } from '../actions';
import * as actions from '../actions';
import {
  changeMainAdminApiCall,
  createUserApiCall,
  deleteUserApiCall,
  getAllUsersApiCall,
  getSharedAccessSignatureForAzureBlobAPICall,
  getUserByIdApiCall,
  getUserGroupApiCall,
  getUsersApiCall,
  patchUserMetadataApiCall,
  postUserSignatureUploadAPICall,
  resetPasswordApiCall,
  updateUserApiCall
} from '../../api';
import { getCurrentUser, getUsersLastSearch, getAffiliatedGroup } from '../selectors';
import { postInviteToZapierAPICall } from '../../api/endpoints/zapier';
import { getContainerURL, getContainerURLNew, transformFile } from '../../utils/azure-blob-helpers';
import { message } from 'antd';

export function* getUsersSaga(action, userId) {
  yield put({ type: actions.GET_USERS_LOADING, loading: true });
  try {
    const { perPage, page, sort, query } = action;
    const profile = yield select(getCurrentUser);
    const users = yield call(
      getUsersApiCall,
      profile,
      perPage,
      page,
      sort,
      query,
      userId
    );
    if(users?.userData) {
      const settingForm = {
        profile: {
          name: users.userData.name,
          phone: users.userData?.user_metadata.phoneNumber,
          email: users.userData.email,
          title: users.userData?.user_metadata.title,
          facility: users.userData?.user_metadata.homeFacility,
          credentials: users.userData?.user_metadata.credentials,
          userType: users.userData?.user_metadata.userType,
          specialty: users.userData?.user_metadata.specialty,
          language: users.userData?.user_metadata.language,
          languageCheckMark: true
        }}
        yield put(actions.loadSettingsAccountForm(settingForm?.profile))
    }

    yield put({
      type: actions.UPDATE_LAST_USERS_SEARCH,
      search: { perPage, page, sort, query }
    });
    yield put({
      type: actions.GET_USERS_SUCCESS,
      data: users
    });
  } catch (error) {
    yield put({ type: actions.GET_USERS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_USERS_LOADING, loading: false });
  }
}

function* getAllUsersSaga() {
  yield put({ type: actions.GET_USERS_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    const users = yield call(getAllUsersApiCall, profile);
    yield put({ type: actions.GET_ALL_USERS_SUCCESS, data: users });
  } catch (error) {
    yield put({ type: actions.GET_ALL_USERS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_USERS_LOADING, loading: false });
  }
}

function* getUserByIdSaga(action) {
  yield put({ type: actions.GET_USER_BY_ID_LOADING, loading: true });
  try {
    const { userId } = action;
    const profile = yield select(getCurrentUser);
    const user = yield call(getUserByIdApiCall, profile, userId);
    yield put({ type: actions.GET_USER_BY_ID_SUCCESS, data: user });
  } catch (error) {
    yield put({ type: actions.GET_USER_BY_ID_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.GET_USER_BY_ID_LOADING, loading: false });
  }
}

function* addUserSaga(action) {
  const { user, signatureFile } = action;
  yield put({ type: actions.CREATE_USER_LOADING, loading: true });
  try {
    const profile = yield select(getCurrentUser);
    if (signatureFile) {
      const { responseOk: sasOk, responseJSON: sasJSON } = yield call(getSharedAccessSignatureForAzureBlobAPICall, profile)
      if (sasOk) {
        const { azure_blob_sas: azureBlobSAS } = sasJSON
        const transformedFile = yield call(transformFile, signatureFile)
        const containerURL = yield call(getContainerURL, { azureBlobSAS, token: profile.accessToken })
        if (containerURL) {
          const { responseOk: uploadOk, url } = yield call(postUserSignatureUploadAPICall, { containerURL, signatureFile: transformedFile })
          if (uploadOk) {
            user.user_metadata.signature = url
            const group = yield select(getAffiliatedGroup);
            const response = yield call(getUserGroupApiCall, profile);
            user.organization_name = response.name;
            yield call(postInviteToZapierAPICall, {
              jsonContent: {
                inviter_name: profile.name,
                inviter_email: profile.email,
                organization_guid: group,
                invitee_name: user.name,
                invitee_emails: user.invitee_email,
                invitee_role: user.user_metadata.userType,
                access_level: user.user_metadata.inviteUserWith
              }
            });
            yield call(createUserApiCall, profile, user);
            let lastSearch = yield select(getUsersLastSearch);
            if (!lastSearch) {
              lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
            }
            yield call(getUsersSaga, lastSearch);
            yield put({ type: actions.ADD_USER_SUCCESS });
            yield put({
              type: actions.GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST
            });
            yield call(showSuccess, 'Invitation Sent');
          }
        }
      } else {
        message.destroy()
        message.error('Unable to save signature. Please try again.', 5)
      }
    } else {
      const group = yield select(getAffiliatedGroup);
      const response = yield call(getUserGroupApiCall, profile);
      user.organization_name = response.name;
      yield call(postInviteToZapierAPICall, {
        jsonContent: {
          inviter_name: profile.name,
          inviter_email: profile.email,
          organization_guid: group,
          invitee_name: user.name,
          invitee_emails: user.invitee_email,
          invitee_role: user.userMetadata.userType,
          access_level: user.userMetadata.inviteUserWith
        }
      });

      yield call(createUserApiCall, profile, user);
      let lastSearch = yield select(getUsersLastSearch);
      if (!lastSearch) {
        lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
      }
      yield call(getUsersSaga, lastSearch);
      yield put({ type: actions.ADD_USER_SUCCESS });
      yield put({
        type: actions.GET_MEDICAL_GROUPS_INVITATIONS_LIST_REQUEST
      });
      yield call(showSuccess, 'Invitation Sent');
    }
  } catch (error) {
    yield put({ type: actions.ADD_USER_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.CREATE_USER_LOADING, loading: false });
  }
}

function* editUserSaga(action) {
  yield put({ type: actions.UPDATE_USER_LOADING, loading: true });
  const { userUpdate, userId, callback } = action;
  try {
    const profile = yield select(getCurrentUser);
    yield call(updateUserApiCall, profile, userId, userUpdate);
    let lastSearch = yield select(getUsersLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    if (callback) {
      yield put(actions.getAllUsersAction());
    }
    yield call(getUsersSaga, lastSearch);
    yield put({ type: actions.EDIT_USER_SUCCESS });
    callback && callback(true);
    yield call(showSuccess, `User has been updated`);
  } catch (error) {
    callback && callback(false);
    yield put({ type: actions.EDIT_USER_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.UPDATE_USER_LOADING, loading: false });
  }
}

function* deleteUserSaga(action) {
  yield put({ type: actions.DELETE_USER_LOADING, loading: true });
  try {
    const { userId } = action;
    const profile = yield select(getCurrentUser);
    yield call(deleteUserApiCall, profile, userId);
    let lastSearch = yield select(getUsersLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getUsersSaga, lastSearch);
    yield put({ type: actions.DELETE_USER_SUCCESS });
    yield call(showSuccess, 'User has been deleted');
  } catch (error) {
    yield put({ type: actions.DELETE_USER_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: actions.DELETE_USER_LOADING, loading: false });
  }
}

function* resetPasswordSaga(action) {
  try {
    const { email } = action;
    const profile = yield select(getCurrentUser);
    const { message } = yield call(resetPasswordApiCall, profile, email);
    yield put({ type: actions.RESET_USER_PASSWORD_SUCCESS });
    yield call(showSuccess, message);
  } catch (error) {
    yield put({ type: actions.DELETE_USER_FAILED, error });
    yield call(showError, error);
  }
}

function* revokeAdminRightsSaga(action: RevokeAdminRightsAction) {
  const { oldAdminId, newAdminId } = action;
  const profile = yield select(getCurrentUser);
  try {
    yield call(changeMainAdminApiCall, profile, oldAdminId, newAdminId);
    yield put({ type: actions.REVOKE_ADMIN_RIGHTS_SUCCESS });
  } catch (error) {
    yield call(showError, error);
    yield put({ type: actions.REVOKE_ADMIN_RIGHTS_FAILED });
  }
}
function* postSignatureUploadHandler({ payload }) {
  yield put({ type: actions.POST_USER_SIGNATURE_UPLOAD_LOADING, loading: true });
  const { signatureFile, useTextOnlySignature, currentUserGuid, requestBody, callback } = payload
  try {
    const profile = yield select(getCurrentUser);
    if (signatureFile) {
      const { responseOk: sasOk, responseJSON: sasJSON } = yield call(getSharedAccessSignatureForAzureBlobAPICall, profile)
      if (sasOk) {
        const { azure_blob_sas: azureBlobSAS } = sasJSON
        const transformedFile = yield call(transformFile, signatureFile)
        const containerURL = yield call(getContainerURL, { azureBlobSAS, token: profile.accessToken })
        if (containerURL) {
          const { responseOk: uploadOk, url } = yield call(postUserSignatureUploadAPICall, { containerURL, signatureFile: transformedFile, currentUserGuid })
          if (uploadOk) {
            requestBody.user_metadata.signature = url
            const response = yield call(patchUserMetadataApiCall, profile, requestBody, currentUserGuid)
            message.destroy()
            if (response) {
              yield put(actions.getUserByIdAction(response.user_id))
              message.success("Signature saved successfully.", 5)
              callback && callback(true)
            }
            else {
              callback && callback(false)
              console.error('Unable to save signature. Please try again.')
              message.destroy()
              message.error('Unable to save signature. Please try again.', 5)
            }
          }
          yield put({ type: actions.POST_USER_SIGNATURE_UPLOAD_LOADING, loading: false });
        }
      } else {
        callback && callback(false)
        yield put({ type: actions.POST_USER_SIGNATURE_UPLOAD_FAILURE })
        yield put({ type: actions.POST_USER_SIGNATURE_UPLOAD_LOADING, loading: false });
        message.destroy()
        message.error('Unable to save signature. Please try again.', 5)
      }
    } else if (useTextOnlySignature) {
      const response = yield call(patchUserMetadataApiCall, profile, requestBody, currentUserGuid)
      if (response) {
        yield put(actions.getUserByIdAction(response.user_id))
        message.success("Signature saved successfully.", 5)
        callback && callback(true)
      } else {
        message.destroy()
        message.error('Unable to save signature. Please try again.', 5)
        callback && callback(false)
      }
      yield put({ type: actions.POST_USER_SIGNATURE_UPLOAD_LOADING, loading: false });

    }
  } catch (e) {
    console.error(e)
    callback && callback(false)
    yield put({ type: actions.POST_USER_SIGNATURE_UPLOAD_FAILURE })
    yield put({ type: actions.POST_USER_SIGNATURE_UPLOAD_LOADING, loading: false });
    message.destroy()
    message.error('Unable to save signature. Please try again.', 5)
  }
}

export default function* userSagas() {
  yield takeLatest(actions.GET_USERS_REQUEST, getUsersSaga);
  yield takeLatest(actions.GET_ALL_USERS_REQUEST, getAllUsersSaga);
  yield takeLatest(actions.GET_USER_BY_ID_REQUEST, getUserByIdSaga);
  yield takeLatest(actions.ADD_USER_REQUEST, addUserSaga);
  yield takeLatest(actions.EDIT_USER_REQUEST, editUserSaga);
  yield takeLatest(actions.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(actions.RESET_USER_PASSWORD_REQUEST, resetPasswordSaga);
  yield takeLatest(actions.REVOKE_ADMIN_RIGHTS_REQUEST, revokeAdminRightsSaga);
  yield takeLatest(actions.POST_USER_SIGNATURE_UPLOAD_REQUEST, postSignatureUploadHandler);
}
