/* eslint-disable no-shadow */
import * as React from 'react';
import { Card, Col, Divider, Row, Typography } from 'antd';
import Box from 'ui-box';
import type {
  StripeSubscription,
  StripeSubscriptionData
} from '../../../schemas';
import {
  centsToUnit,
  getStripeSubscriptionKind,
  renderCurrency
} from '../../../utils';

import './PlanDetails.less';

const { Title } = Typography;

const TitleContainer = ({ children }) => (
  <div className='sub-title'>{children}</div>
);

type Props = {
  currency: string,
  subscriptions: StripeSubscriptionData
};

export default ({ currency, subscriptions }: Props) => {
  const { main, addOns } = subscriptions;
  const amount = [...addOns, main]
    .map((s: StripeSubscription) => s.unitAmount * s.quantity)
    .reduce((a, v) => a + v);

  const getDetails = (name: string) => {
    switch (getStripeSubscriptionKind(name)) {
      case 'CONNECT':
        return ({ quantity }) => (
          <TitleContainer>
            {quantity} {quantity > 1 ? ' Sites' : ' Site'}
          </TitleContainer>
        );
      case 'BOX':
        return ({ quantity, unitAmount, currency }) => (
          <TitleContainer>
            {quantity} {quantity > 1 ? ' Boxes' : ' Box'} —{' '}
            {centsToUnit(unitAmount)} {renderCurrency(currency)} / box
          </TitleContainer>
        );
      case 'HUB':
        return ({ quantity, currency, unitAmount }) => (
          <TitleContainer>
            {quantity} {quantity > 1 ? ' Hubs' : ' Hub'} -{' '}
            {centsToUnit(unitAmount)} {renderCurrency(currency)} / hub
          </TitleContainer>
        );
      case 'OPINION':
        return ({ quantity, currency, unitAmount }) => (
          <TitleContainer>
            {quantity} {quantity > 1 ? ' Portals' : ' Portal'} -{' '}
            {centsToUnit(unitAmount)} {renderCurrency(currency)} / portal
          </TitleContainer>
        );
      case 'ROUTINE':
        return ({ quantity, currency, unitAmount }) => (
          <TitleContainer>
            {quantity} {quantity > 1 ? ' Routines' : ' Routine'} -{' '}
            {centsToUnit(unitAmount)} {renderCurrency(currency)} / routine
          </TitleContainer>
        );
      default:
        return _ => <TitleContainer> </TitleContainer>;
    }
  };

  const renderPlanDetails = (
    subscription: {
      id: string,
      name: string,
      currency: string,
      amount: number
    },
    separator = true,
    details = true
  ) => {
    const { id, name, currency, amount } = subscription;
    return (
      <React.Fragment key={id}>
        <Row gutter={24} type='flex' justify='space-between' align='middle'>
          <Col>
            <Title level={4} className='title'>
              {name}
            </Title>
          </Col>
          <Col>
            <Row gutter={24} type='flex' align='middle'>
              <Col>
                <Title level={4} className='title'>
                  {`$${centsToUnit(amount || 0)}`}
                </Title>
              </Col>
              <Col>
                <Title level={4} className='title'>
                  {renderCurrency(currency)}
                </Title>
              </Col>
            </Row>
          </Col>
        </Row>
        {details && getDetails(name)(subscription)}
        {separator && <Divider style={{ margin: '16px 0' }} />}
      </React.Fragment>
    );
  };

  return (
    <Card title='Plan Details' className='plan-details'>
      {renderPlanDetails({ ...main, amount: main.unitAmount * main.quantity })}
      {addOns.length > 0 && (
        <Box paddingTop={4} paddingBottom={7}>
          <Title level={4} className='title'>
            Add-Ons
          </Title>
        </Box>
      )}
      {addOns.map(a =>
        renderPlanDetails({ ...a, amount: a.unitAmount * a.quantity })
      )}
      {renderPlanDetails({
        id: '_',
        name: 'Current Monthly Subscription',
        currency,
        amount
      })}
      <Box paddingBottom={60.5} />
    </Card>
  );
};
