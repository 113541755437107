import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/externalRadiologistsActions';
import {
  ADD_EXTERNAL_RAD_LOADING,
  GET_EXTERNAL_RAD_LOADING,
  GET_EXTERNAL_RADS_LOADING
} from '../actions';
import { showError, showSuccess } from '../helpers';
import {
  createExternalRadiologistApiCall,
  createUserApiCall,
  deleteExternalRadiologistApiCall,
  getExternalRadiologistByIdApiCall,
  getExternalRadiologistsApiCall
} from '../../api';
import { getCurrentUser } from '../selectors';
import { getExternalLastSearch } from '../selectors/radiologistsSelectors';

function* getExternalRadiologistsSaga(action) {
  yield put({ type: GET_EXTERNAL_RADS_LOADING, loading: true });
  try {
    const { perPage, page, sort, query } = action;
    const profile = yield select(getCurrentUser);
    const radiologists = yield call(
      getExternalRadiologistsApiCall,
      profile,
      perPage,
      page,
      sort,
      query
    );
    yield put({
      type: actions.UPDATE_LAST_EXTERNAL_RADIOLOGISTS_SEARCH,
      search: { perPage, page, sort, query }
    });
    yield put({
      type: actions.GET_EXTERNAL_RADIOLOGISTS_SUCCESS,
      data: radiologists
    });
  } catch (error) {
    yield put({ type: actions.GET_EXTERNAL_RADIOLOGISTS_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_EXTERNAL_RADS_LOADING, loading: false });
  }
}

function* getExternalRadiologistByIdSaga(action) {
  yield put({ type: GET_EXTERNAL_RAD_LOADING, loading: true });
  try {
    const { radId } = action;
    const profile = yield select(getCurrentUser);
    const rad = yield call(getExternalRadiologistByIdApiCall, profile, radId);
    yield put({ type: actions.GET_EXTERNAL_RAD_BY_ID_SUCCESS, data: rad });
  } catch (error) {
    yield put({ type: actions.GET_EXTERNAL_RAD_BY_ID_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: GET_EXTERNAL_RAD_LOADING, loading: false });
  }
}

function* addExternalRadiologistSaga(action) {
  yield put({ type: ADD_EXTERNAL_RAD_LOADING, loading: true });
  try {
    const { radiologist } = action;
    const profile = yield select(getCurrentUser);
    // const group = yield select(getAffiliatedGroup);
    // yield call(postInviteToZapierAPICall, {
    //   jsonContent: {
    //     inviter_name: profile.name,
    //     inviter_email: profile.email,
    //     organization_guid: group,
    //     invitee_name: radiologist.name,
    //     invitee_emails: radiologist.invitee_email,
    //     invitee_role:  radiologist.userMetadata && radiologist.userMetadata.userType,
    //     access_level: "Limited Access"
    //   }
    // });
    const resp: { message: string } = yield call(
      createExternalRadiologistApiCall,
      profile,
      radiologist
    );

    // const requestBody = {
    //   inviter_name: profile.name,
    //   invitees: [
    //     {
    //       name: radiologist.name,
    //       email: radiologist.email
    //     }
    //   ]
    // };
    if (resp.message) {
      yield call(createUserApiCall, profile, radiologist);
      // yield call(inviteColleaguesApiCall, profile, requestBody);
      yield put({ type: actions.ADD_EXTERNAL_RAD_SUCCESS });
      yield call(showSuccess, resp.message);
    }
    // let lastSearch = yield select(getUsersLastSearch);
    // if (!lastSearch) {
    //   lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    // }
    // yield call(getExternalRadiologistsSaga, lastSearch);
  } catch (error) {
    yield put({ type: actions.ADD_EXTERNAL_RAD_FAILED, error });
    yield call(showError, error);
  } finally {
    yield put({ type: ADD_EXTERNAL_RAD_LOADING, loading: false });
  }
}

function* deleteExternalRadiologistSaga(action) {
  try {
    const { radId } = action;
    const profile = yield select(getCurrentUser);
    yield call(deleteExternalRadiologistApiCall, profile, radId);
    let lastSearch = yield select(getExternalLastSearch);
    if (!lastSearch) {
      lastSearch = { perPage: -1, page: -1, sort: '', query: '' };
    }
    yield call(getExternalRadiologistsSaga, lastSearch);
    yield put({ type: actions.DELETE_EXTERNAL_RAD_SUCCESS });
    yield call(showSuccess, 'Radiologist has been deleted');
  } catch (error) {
    yield put({ type: actions.DELETE_EXTERNAL_RAD_FAILED, error });
    yield call(showError, error);
  }
}

export default function* externalRadiologistsSaga() {
  yield takeLatest(
    actions.GET_EXTERNAL_RADIOLOGISTS_REQUEST,
    getExternalRadiologistsSaga
  );
  yield takeLatest(
    actions.GET_EXTERNAL_RAD_BY_ID_REQUEST,
    getExternalRadiologistByIdSaga
  );
  yield takeLatest(
    actions.ADD_EXTERNAL_RAD_REQUEST,
    addExternalRadiologistSaga
  );
  yield takeLatest(
    actions.DELETE_EXTERNAL_RAD_REQUEST,
    deleteExternalRadiologistSaga
  );
}
