import { ReactComponent as Doctor } from './doctor.svg';
import { ReactComponent as Billing } from './billing.svg';
import { ReactComponent as Clinic } from './clinic.svg';
import { ReactComponent as DashBoard } from './dashboard.svg';
import { ReactComponent as Support } from './support.svg';
import { ReactComponent as UserAdmin } from './user-admin.svg';
import { ReactComponent as Medical } from './medical.svg';
import { ReactComponent as ReferringPhysician } from './referring-physician.svg';

export default {
  dashboard: DashBoard,
  user: UserAdmin,
  network: Doctor,
  facilities: Clinic,
  plan: Billing,
  support: Support,
  medical: Medical,
  refuser: ReferringPhysician
};
