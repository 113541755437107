// @flow

import * as React from 'react';
import { Card, Icon, Row, Col, Typography, Button } from 'antd';
import Box from 'ui-box';
import { centsToUnit, formatDate, renderCurrency } from '../../../utils';

import PaymentModal from './PaymentModal';
import Tooltip from '../../../components/Tooltip';

import './YourAccount.less';

const { Title } = Typography;

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: '#606060',
    marginBottom: 12
  },
  btn: {
    fontWeight: 'bold',
    fontSize: 14
  }
};

type Props = {
  renewalDate: string,
  balance: number,
  currency: string
};

export default ({ renewalDate, balance, currency }: Props) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <PaymentModal
        currency={currency}
        visible={visible}
        // eslint-disable-next-line no-shadow
        setVisibility={visible => setVisible(visible)}
        totalQuantity={balance}
      />
      <Card title='Your Account' className='your-account-card'>
        <Box>
          <Row type='flex' justify='space-between'>
            <Col>
              <Title level={4} style={styles.title}>
                Renewal Date
              </Title>
            </Col>
            <Col>
              <Title level={4} style={styles.title}>
                {formatDate(renewalDate)}
              </Title>
            </Col>
          </Row>
          <Row type='flex' justify='space-between'>
            <Col>
              <Title level={4} style={styles.title}>
                Current Balance
              </Title>
            </Col>
            <Col>
              <Title level={4} style={{ ...styles.title, color: '#02C209' }}>
                {`$${centsToUnit(balance || 0)} ${renderCurrency(currency)}`}
              </Title>
            </Col>
          </Row>
        </Box>
        <Box paddingTop={12}>
          <Row type='flex' justify='end'>
            <Col>
              <Tooltip
                active={balance <= 0}
                text='There is no pending balance to make a payment'
              >
                <Button
                  disabled={balance <= 0}
                  type='primary'
                  style={styles.btn}
                  onClick={() => setVisible(true)}
                >
                  Make a Payment <Icon type='arrow-right' />
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </Box>
      </Card>
    </>
  );
};
