import * as React from 'react';
import { Modal, Switch, Input, Typography, Row, Col, Divider } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { showError } from '../../../store/helpers';
import { getCurrentUser } from '../../../store/selectors';
import { createStripePaymentEndpoint } from '../../../api/endpoints/payments';
import { centsToUnit, renderCurrency } from '../../../utils';

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: 14
  }
};

const { Title } = Typography;

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PRODUCTION === '0'
    ? process.env.REACT_APP_STRIPE_TEST_API_KEY
    : process.env.REACT_APP_STRIPE_API_KEY
);

type Props = {
  visible: boolean,
  totalQuantity: number,
  currency: string,
  setVisibility: boolean => void
};

export default ({ visible, totalQuantity, currency, setVisibility }: Props) => {
  const currentLocation = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

  const toQuantityString = (value: number) => {
    let result = value !== 0 ? (value / 100).toLocaleString('en-US') : '0.00';
    if ((result.match(/\./g) || []).length === 0) {
      result = `${result}.00`;
    }
    return result;
  };

  const [state, setState] = React.useState({
    quantity: totalQuantity,
    quantityString: toQuantityString(totalQuantity),
    total: true
  });

  const profile = useSelector(getCurrentUser);

  const createStripeSession = async () =>
    createStripePaymentEndpoint(profile, {
      quantity: state.quantity,
      currency,
      success: `${currentLocation}?stripe=success`,
      cancel: `${currentLocation}?stripe=error`
    });

  const checkout = async (_event: any) => {
    const stripe = await stripePromise;
    try {
      const token = await createStripeSession();
      const { error } = await stripe.redirectToCheckout({
        sessionId: token
      });
      if (error && error.message) {
        showError(error.message);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('createStripeSession', e);
      showError(
        new Error(`Something went wrong creating checkout session: ${e.message}.
      Please, reload the page and contact us if the problem persists.`)
      );
    }
  };

  return (
    <Modal
      visible={visible}
      title='Make a payment'
      okText='Checkout'
      cancelText='Close'
      onOk={checkout}
      onCancel={() => setVisibility(false)}
    >
      <div className='payments-modal'>
        <Row justify='start' align='middle' gutter={[24, 0]}>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Title level={4} style={styles.title}>
              Pay current balance in full
            </Title>
          </Col>
          <Col span={12}>
            <Switch
              checkedChildren='Yes'
              unCheckedChildren='No'
              defaultChecked
              onChange={checked => {
                const quantity = checked ? totalQuantity : state.quantity;
                setState({
                  ...state,
                  total: checked,
                  quantity,
                  quantityString: centsToUnit(quantity)
                });
              }}
            />
          </Col>
        </Row>
        <Divider />
        {state.total ? (
          <Row justify='start' align='middle' gutter={[24, 0]}>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Title level={4} style={styles.title}>
                Make a payment of{' '}
              </Title>
            </Col>
            <Col span={12}>
              <Title level={4} style={{ ...styles.title, color: '#02C209' }}>
                {`$${centsToUnit(totalQuantity || 0)} ${renderCurrency(
                  currency
                )}`}
              </Title>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={14}>
              <Title level={4} style={{ ...styles.title, paddingTop: 5 }}>
                Top-up account by this amount:
              </Title>
            </Col>
            <Col span={10}>
              <Input
                prefix='$'
                suffix={renderCurrency(currency)}
                defaultValue={totalQuantity / 100}
                value={state.quantityString}
                onChange={event => {
                  const { value } = event.target;
                  const nValue = Number(value.replace(/,/g, ''));
                  if (nValue > -1) {
                    setState({
                      ...state,
                      quantity: nValue * 100,
                      quantityString: toQuantityString(nValue * 100)
                    });
                  }
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};
