import { message } from 'antd/lib/index';

export const getFormValues = (state, name) =>
  state.form[name] && state.form[name].values;

export function showError(error) {
  if (error.message) {
    return new Promise(resolve => {
      message.error(error.message, 5);
      setTimeout(() => resolve(true), 5000);
    });
  }
  return null;
}

export function showSuccess(text) {
  return new Promise(resolve => {
    message.success(text, 5);
    setTimeout(() => resolve(true), 5000);
  });
}

export function getQuery(filters) {
  let query = '';
  Object.entries(filters)
    .filter(e => e[1].length > 0)
    .forEach(e =>
      typeof e[1] === 'string'
        ? (query += `${e[0]}:${e[1]}+`)
        : e[1].forEach(e1 => (query += `${e[0]}:${e1}+`))
    );
  query = query.slice(0, -1);
  return query;
}

export const createReducer = (initialState, reducerMap = {}) => (
  state = initialState,
  action
) => {
  const reducer = reducerMap[action.type];
  return reducer ? reducer(state, action) : state;
};
