/* eslint-disable */
import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { UpdateGroupAction } from '../actions/groupActions';
import * as actions from '../actions/groupActions';
import type { UpdatePrintAction } from '../actions';
import { GET_GROUP_LOADING, UPDATE_GROUP_LOADING } from '../actions';

import {
  createGroupApiCall,
  getUserGroupApiCall,
  updateGroupAPiCall
} from '../../api';
import { showError, showSuccess } from '../helpers';
import type { UserProfile } from '../../schemas';
import { getCurrentUser } from '../selectors';
import { getGroupLetterhead, getGroupNewLetterhead } from '../selectors';

function* getGroupSaga() {
  yield put({ type: GET_GROUP_LOADING, loading: true });
  const profile: UserProfile = yield select(getCurrentUser);
  try {
    const group = yield call(getUserGroupApiCall, profile);
    yield put({ type: actions.GET_GROUP_SUCCESS, group });
  } catch (e) {
    try {
      yield call(createGroupApiCall, profile, {
        group_id: profile.appMetadata.affiliatedGroup,
        name: ''
      });
      const group = yield call(getUserGroupApiCall, profile);
      yield put({ type: actions.GET_GROUP_SUCCESS, group });
    } catch (err) {
      yield put({ type: actions.GET_GROUP_FAILURE });
      yield call(showError, err);
    }
  } finally {
    yield put({ type: GET_GROUP_LOADING, loading: false });
  }
}

function* updateGroupSaga(action: UpdateGroupAction) {
  yield put({ type: UPDATE_GROUP_LOADING, loading: true });
  const profile: UserProfile = yield select(getCurrentUser);
  try {
    yield call(updateGroupAPiCall, profile, action.group);
    const group = yield call(getUserGroupApiCall, profile);
    yield put({ type: actions.GET_GROUP_SUCCESS, group });
  } catch (e) {
    yield put({ type: actions.UPDATE_GROUP_FAILURE });
    yield call(showError, e);
  } finally {
    yield put({ type: UPDATE_GROUP_LOADING, loading: false });
  }
}

function* updateGroupPrint(action: UpdatePrintAction) {
  yield put({ type: UPDATE_GROUP_LOADING, loading: true });
  const profile: UserProfile = yield select(getCurrentUser);
  const oldLetterhead = yield select(getGroupLetterhead);
  const newLetterhead = yield select(getGroupNewLetterhead);
  const letterhead = newLetterhead || oldLetterhead;
  try {
    yield call(updateGroupAPiCall, profile, { ...action.data, letterhead });
    const group = yield call(getUserGroupApiCall, profile);
    yield put({ type: actions.GET_GROUP_SUCCESS, group });
    yield call(showSuccess, 'Print group settings updated');
  } catch (e) {
    yield put({ type: actions.UPDATE_GROUP_FAILURE });
    yield call(showError, e);
  } finally {
    yield put({ type: UPDATE_GROUP_LOADING, loading: false });
  }
}

export default function* groupSaga() {
  yield takeLatest(actions.GET_GROUP_REQUEST, getGroupSaga);
  yield takeLatest(actions.UPDATE_GROUP_REQUEST, updateGroupSaga);
  yield takeLatest(actions.UPDATE_GROUP_PRINT, updateGroupPrint);
}
