import * as React from 'react';
import Box from 'ui-box';
import { Button, Card, Col, Icon, Modal, Row, Spin, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { centsToUnit, formatDate } from '../../../utils';
import type {
  ReduxState,
  StripeInvoice,
  StripeInvoiceData
} from '../../../schemas';
import ActionLink from '../../../components/ActionLink';
import {
  getStripeInvoicesAction,
  restoreStripeInvoices
} from '../../../store/actions/paymentsActions';

import './PaymentInformation.less';
import Tooltip from '../../../components/Tooltip';

const { Title } = Typography;

type Props = {
  invoiceData: StripeInvoiceData
};

export default ({ invoiceData }: Props) => {
  // Constants
  const INVOICES_LIMIT = 2;

  // State
  const [state, setState] = React.useState({
    historyModalVisible: false,
    page: 0
  });
  // eslint-disable-next-line no-unused-vars
  const { historyModalVisible } = state;

  // Data
  const { data, hasMore } = invoiceData;
  const loading: boolean = useSelector(
    (stateLoading: ReduxState) => stateLoading.loading.getStripeData
  );
  const invoices: StripeInvoiceData = useSelector(
    (stateInvice: ReduxState) => stateInvice.payments.invoices
  );
  const invoiceArray = invoices.data;
  const hasMoreInvoices = invoices.hasMore;

  // Actions
  const dispatch = useDispatch();
  const openModal = () => {
    dispatch(getStripeInvoicesAction(INVOICES_LIMIT));
    setState({ ...state, historyModalVisible: true });
  };
  const closeModal = () => {
    setState({ ...state, historyModalVisible: false, page: 0 });
    dispatch(restoreStripeInvoices());
  };
  const renderMoreInvoices = (
    firstInvoice: StripeInvoice,
    lastInvoice: StripeInvoice = { id: '' }
  ) =>
    dispatch(
      getStripeInvoicesAction(INVOICES_LIMIT, firstInvoice.id, lastInvoice.id)
    );

  // Render functions
  const renderPaymentInfoRow = ({
    id,
    date,
    currency,
    balance,
    invoicePdf
  }) => {
    return (
      <Box
        marginBottom={32}
        key={id}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          window.open(invoicePdf);
        }}
      >
        <Row
          key={id}
          gutter={24}
          type='flex'
          justify='space-between'
          align='middle'
        >
          <Col>
            <Title level={4} className='title'>
              {formatDate(date)}
            </Title>
          </Col>
          <Col>
            <Row gutter={24} type='flex' align='middle'>
              <Col>
                <Title level={4} className='title'>
                  ${centsToUnit(balance)}
                </Title>
              </Col>
              <Col>
                <Title level={4} className='title'>
                  {currency && typeof currency === 'string'
                    ? currency.toUpperCase()
                    : currency}
                </Title>
              </Col>
              <Col>
                <Icon type='file-text' className='icon' />
              </Col>
            </Row>
          </Col>
        </Row>
      </Box>
    );
  };

  return (
    <div className='payment-information'>
      {historyModalVisible ? ( // We have to use this trick to force form destruction
        <Modal
          visible={historyModalVisible}
          title='Payments history'
          footer={
            <Row type='flex' justify='space-around' align='middle'>
              <Col span={12} offset={12} style={{ textAlign: 'right' }}>
                <Button onClick={closeModal}>Close</Button>
              </Col>
            </Row>
          }
          onCancel={closeModal}
        >
          {' '}
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin size='large' />
            </div>
          ) : (
            <div className='payment-information'>
              {invoiceArray.map((i: StripeInvoice) =>
                renderPaymentInfoRow({
                  id: i.id,
                  date: i.periodEnd,
                  currency: i.currency,
                  balance: i.total,
                  i
                })
              )}
              <Row
                gutter={24}
                type='flex'
                justify='space-between'
                align='middle'
              >
                <Col>
                  <ActionLink
                    click={() => {
                      setState({ ...state, page: state.page - 1 });
                      renderMoreInvoices(invoiceArray[0]);
                    }}
                    text='Previous'
                    disabled={state.page === 0}
                  />
                </Col>
                <Col>
                  <ActionLink
                    click={() => {
                      setState({ ...state, page: state.page + 1 });
                      renderMoreInvoices(
                        { id: '' },
                        invoiceArray[invoiceArray.length - 1]
                      );
                    }}
                    text='Load more'
                    disabled={!hasMoreInvoices}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Modal>
      ) : null}
      <Card title='Payment Information'>
        {data.map((i: StripeInvoice) =>
          renderPaymentInfoRow({
            ...i,
            date: i.periodEnd,
            balance: i.total
          })
        )}
        <Tooltip text='There are no previous payments' active={!hasMore}>
          <ActionLink
            click={openModal}
            text='See previous payments'
            disabled={!hasMore}
          />
        </Tooltip>
      </Card>
    </div>
  );
};
