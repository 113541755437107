import * as React from 'react';
import { Breadcrumb } from 'antd';
import { withRouter } from 'react-router-dom';

import { t } from 'i18next';
import { pathMatchRegexp, queryAncestors } from '../../utils';

import './Bread.less';

export default withRouter(
  class Bread extends React.PureComponent {
    generateBreadcrumbs = paths => {
      return paths.map((item, key) => {
        const content = <>{t(`side_bar.${item.name}`)}</>;

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Breadcrumb.Item key={key}>
            {paths.length - 1 !== key
              ? content
              : // <Link to={item.route || '#'}>{content}</Link>
                content}
          </Breadcrumb.Item>
        );
      });
    };

    render() {
      const { routeList, location } = this.props;

      // Find a route that matches the pathname.
      const currentRoute = routeList.find(
        _ => _.route && pathMatchRegexp(_.route, location.pathname)
      );

      // Find the breadcrumb navigation of the current route match and all its ancestors.
      const paths = currentRoute
        ? queryAncestors(
            routeList,
            currentRoute,
            'breadcrumbParentId'
          ).reverse()
        : [
            routeList[0],
            {
              id: 404,
              name: `Not Found`
            }
          ];

      return (
        <Breadcrumb className='bread'>
          {this.generateBreadcrumbs(paths)}
        </Breadcrumb>
      );
    }
  }
);
