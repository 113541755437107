/**
 * Uses the country list object to generate a list with the filters structure.
 * @returns {List} A list of objects with shape {name: Spain, value: Spain}
 */
export const countryFilter = countryList =>
  countryList.map(country => ({ name: country.name, value: country.name }));

export const YES_NO = [
  { text: 'No', value: '0' },
  { text: 'Yes', value: '1' }
];

export const CUSTOM_LETTERHEAD = [
  { text: 'Group Default', value: 'default' },
  { text: 'Custom', value: 'custom' }
];

export const GROUP_LETTERHEAD = [
  { text: 'Letterhead', value: 'yes' },
  { text: 'No Letterhead', value: 'no' }
];

export const HOME_FACILITIES = [
  { text: 'Main Facility', value: 'Main Facility' },
  { text: 'Group Facility 1', value: 'Group Facility 1' },
  { text: 'Group Facility 2', value: 'Group Facility 2' },
  { text: 'Crestview NOHIL', value: 'Crestview NOHIL' },
  { text: 'Crestview Agege', value: 'Crestview Agege' },
  { text: 'Crestview Igbobi', value: 'Crestview Igbobi' }
];


export const languageMap = [
  { text: 'English', value: 'en' },
  { text: 'Español', value: 'es' },
  { text: 'Français', value: 'fr' }
];

export const USER_TYPE = [
  { text: 'Management', value: 'Management' },
  { text: 'Radiographer', value: 'Radiographer' },
  { text: 'Radiologist', value: 'Radiologist' },
  { text: 'Radiologist Assistant', value: 'Radiologist Assistant' },
  { text: 'Coordinator', value: 'Coordinator' },
  { text: 'Administrator', value: 'Administrator' },
  { text: 'Independent Radiologist', value: 'Independent Radiologist' }
];

export const RAD_SPECIALTY = [
  { text: 'Abdominal Imaging', value: 'Abdominal Imaging' },
  { text: 'Breast Imaging', value: 'Breast Imaging' },
  { text: 'Cardiac Imaging', value: 'Cardiac Imaging' },
  { text: 'Emergency Radiology', value: 'Emergency Radiology' },
  { text: 'General Radiology', value: 'General Radiology ' },
  { text: 'Musculoskeletal Imaging', value: 'Musculoskeletal Imaging' },
  {
    text: 'Neuro-interventional Radiology',
    value: 'Neuro-interventional Radiology'
  },
  { text: 'Neuroradiology', value: 'Neuroradiology' },
  { text: 'Nuclear Medicine', value: 'Nuclear Medicine' },
  { text: 'Oncological Radiology', value: 'Oncological Radiology' },
  { text: 'Pediatric Radiology', value: 'Pediatric Radiology' },
  { text: 'Prostate Radiology', value: 'Prostate Radiology' },
  { text: 'Thoracic Imaging', value: 'Thoracic Imaging' },
  {
    text: 'Vascular Interventional Radiology',
    value: 'Vascular Interventional Radiology'
  }
];

export const PHYSICIAN_SPECIALTY = {
  Custom: { label: 'None of these', value: 'Custom' },
  'Allergy & Immunology': {
    label: 'Allergy & Immunology',
    value: 'Allergy & Immunology'
  },
  Anesthesiology: { label: 'Anesthesiology', value: 'Anesthesiology' },
  Dermatology: { label: 'Dermatology', value: 'Dermatology' },
  'Emergency Medicine': {
    label: 'Emergency Medicine',
    value: 'Emergency Medicine'
  },
  'Family Medicine': { label: 'Family Medicine', value: 'Family Medicine' },
  'Internal Medicine': {
    label: 'Internal Medicine',
    value: 'Internal Medicine'
  },
  'Medical Genetics': { label: 'Medical Genetics', value: 'Medical Genetics' },
  'Neurological Surgery': {
    label: 'Neurological Surgery',
    value: 'Neurological Surgery'
  },
  'Nuclear Medicine': { label: 'Nuclear Medicine', value: 'Nuclear Medicine' },
  'Obstetrics & Gynecology': {
    label: 'Obstetrics & Gynecology',
    value: 'Obstetrics & Gynecology'
  },
  Oncology: { label: 'Oncology', value: 'Oncology' },
  Otolaryngology: { label: 'Otolaryngology', value: 'Otolaryngology' },
  'Pathology-Anatomic & Clinical': {
    label: 'Pathology-Anatomic & Clinical',
    value: 'Pathology-Anatomic & Clinical'
  },
  Pediatrics: { label: 'Pediatrics', value: 'Pediatrics' },
  'Physical Medicine & Rehabilitation': {
    label: 'Physical Medicine & Rehabilitation',
    value: 'Physical Medicine & Rehabilitation'
  },
  'Preventive Medicine': {
    label: 'Preventive Medicine',
    value: 'Preventive Medicine'
  },
  Psychiatry: { label: 'Psychiatry', value: 'Psychiatry' },
  'Radiology-Diagnostic': {
    label: 'Radiology-Diagnostic',
    value: 'Radiology-Diagnostic'
  },
  'Sleep Medicine': { label: 'Sleep Medicine', value: 'Sleep Medicine' },
  'Surgery-General': { label: 'Surgery-General', value: 'Surgery-General' },
  'Thoracic Surgery': { label: 'Thoracic Surgery', value: 'Thoracic Surgery' },
  Urology: { label: 'Urology', value: 'Urology' }
};

export const STUDIES = [
  { text: 'All studies', value: 'All studies' },
  { text: 'Assigned Studies and priors', value: 'Assigned Studies and priors' }
];

export const PHONE_KIND = [
  { text: 'Office', value: 'office' },
  { text: 'Mobile', value: 'mobile' },
  { text: 'Home', value: 'home' }
];

export const HARDWARE_TYPE = [
  { text: 'AlemBox Pro', value: 'plan_3' },
  { text: 'AlemHub Enterprise', value: 'plan_4' }
];

export const FACILITY_TYPE = [
  { text: 'Diagnostic Imaging Centre', value: 'diagnostic-imaging-center' },
  { text: 'Hospital', value: 'hospital' },
  { text: 'Health Clinic', value: 'health-clinic' },
  { text: 'Assisted/Senior Living Facility', value: 'senior-living-facility' },
  { text: 'Speciality Clinic', value: 'speciality-clinic' },
  { text: 'Teleradiology Provider', value: 'teleradiology-provider' },
  { text: 'Other', value: 'other' }
];

export const CURRENCY_TYPE = [
  { text: 'USD', value: 'USD' },
  { text: 'EUR', value: 'EUR' },
  { text: 'GBP', value: 'GBP' },
  { text: 'SGP', value: 'SGP' },
  { text: 'NGN', value: 'NGN' }
];

export const USER_STATUS = [
  { text: 'Active', value: 'Active' },
  { text: 'Invited', value: 'Invited' },
  { text: 'Blocked', value: 'Blocked' }
];
