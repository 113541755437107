/**
 * Api endpoints to retrieve the information and change the users of the affiliated group of the calling user. Every
 * method requires admin privileges, as only admins are allowed to see the data of other users of the group.
 */
import PubNub from 'pubnub';
import { eventChannel } from 'redux-saga';
import moment from 'moment';
import AuthService from '../index';
import Logger from '../../utils/logger';
import {
  deviceToJSON,
  jsonToDevice,
  updatedDeviceToJSON
} from '../transformers';

import type { Device, ApiService, UserProfile } from '../../schemas';

const API_SERVICE: ApiService = 'devices';
const BASE_ROUTE = '/device';

const pubnub = new PubNub({
  subscribeKey: 'sub-c-df2ceddc-04be-11ec-ac05-0664d1b72b66',
  publishKey: 'pub-c-eb69a0e3-9bd7-4d9b-bd3d-914e94db7c77',
  logVerbosity: true,
  ssl: true
});

// /**
//  * Get the users of the user affiliated group. Requires admin privileges.
//  * @param profile: user profile
//  * @param perPage: number of results to get by page
//  * @param page: page of results
//  * @param sort: column to order the returned results.
//  * @param query: a query string that represents additional queries to pass to limit user results. If multiple queries
//  * shall be passed, they must be separated by a plus ("+") sign. Each query follows the lucene query format (key:value)
//  * For example: `name:Mark+email:mark@radiologist.com`
//  * @returns {never|Promise<Response>} A promise with an http response containing a list of users
//  */
// export const getUsersApiCall = async (
//   profile: UserProfile,
//   perPage: number,
//   page: number,
//   sort: string,
//   query: string
// ) => {
//   let route = BASE_ROUTE;
//   if (perPage > 0 || page > 0 || sort.length > 0 || query.length > 0) {
//     route += querier(perPage, page, sort, query);
//   }
//   Logger.log('GET users api call with route: ', route);
//   const Auth = await AuthService(profile.accessToken.length > 0);
//   return new Auth(profile)
//     .service(API_SERVICE, route, {})
//     .then((response: MultipleUserResponse) => {
//       Logger.log('GET Users api response: ', response);
//       return {
//         array: response.users.map(u => jsonToUser(u)),
//         total: response.total
//       };
//     });
// };

// export const getAllUsersApiCall = async (profile: UserProfile) => {
//   const route = BASE_ROUTE;
//   const Auth = await AuthService(profile.accessToken.length > 0);
//   return new Auth(profile)
//     .service(API_SERVICE, route, {})
//     .then((response: MultipleUserResponse) => {
//       Logger.log('GET Users api response: ', response);
//       return {
//         array: response.users.map(u => jsonToUser(u)),
//         total: response.total
//       };
//     });
// };
// /**
//  * Retrieves a user by id. Requires admin privileges.
//  * @param profile: user profile
//  * @param id: id of the user to retrieve
//  * @returns {never|Promise<*>}: the radiologist with the provided id
//  */
// export const getUserByIdApiCall = async (profile: UserProfile, id: string) => {
//   Logger.log('GET User by id api call with id: ', id);
//   const Auth = await AuthService(profile.accessToken.length > 0);
//   return new Auth(profile)
//     .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {})
//     .then(u => {
//       Logger.log('GET User by id api response: ', u);
//       return jsonToUser(u);
//     });
// };

/**
 * Create a device. Requires admin privileges.
 * @param profile: user profile
 * @param device the device data
 * @returns {never|Promise<*>} Returns the created device
 */
// eslint-disable-next-line import/prefer-default-export
export const createDeviceApiCall = async (
  profile: UserProfile,
  device: Device
) => {
  Logger.log('POST device api call ', device);
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, BASE_ROUTE, {
      method: 'POST',
      body: deviceToJSON(device)
    })
    .then(r => {
      Logger.log('POST device api call response: ', r);
      return r;
    });
};

/**
 * Edit a device. Requires admin privileges.
 * @param profile: user profile
 * @param device the device data
 * @param id id of the device data
 * @returns {never|Promise<*>} Returns the created device
 */
// eslint-disable-next-line import/prefer-default-export
export const editDeviceApiCall = async (
  profile: UserProfile,
  device: Device,
  id: string
) => {
  Logger.log('PUT device api call ', device);
  const Auth = await AuthService(profile.accessToken.length > 0);

  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, {
      method: 'PUT',
      body: updatedDeviceToJSON(device)
    })
    .then(r => {
      Logger.log('PUT device api call response: ', r);
      return r;
    });
};

export const getAllDevicesApiCall = async (profile: UserProfile) => {
  const route = `/devices/organization/${profile.appMetadata.affiliatedGroup}`;
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile).service(API_SERVICE, route, {}).then(response => {
    Logger.log('GET Devices api response: ', response);
    return {
      array: response.map(d => jsonToDevice(d)),
      total: response.length
    };
  });
};

/**
 * Get the facilities status available for a facility in the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a status of a facility
 */
export const getDevicesStatusApiCall = (profile: UserProfile) => {
  pubnub.setUUID(profile.id);
  const channel = `organization.${profile.appMetadata.affiliatedGroup}.modality-status-result`;

  return eventChannel(emit => {
    pubnub.addListener({
      message: messageEvent => {
        emit(messageEvent);
      }
      // presence: presenceEvent => {
      //   pubnub.hereNow(
      //     {
      //       channels: [channel],
      //       includeUUIDs: true,
      //       includeState: true
      //     },
      //     (status, response) => {
      //       Logger.log('New my facilities status received');
      //       emit(response.channels[channel]);
      //     }
      //   );
      // }
    });

    pubnub.subscribe({
      channels: [channel],
      withPresence: true
    });

    return () => {
      pubnub.unsubscribe({
        channels: [channel]
      });
    };
  });
};

/**
 * Get the facilities status available for a facility in the group.
 * @param profile: user profile
 * @returns {never|Promise<Response>} A promise with an http response containing a status of a facility
 */
export const testDeviceStatusApiCall = (
  profile: UserProfile,
  device,
  messageGuid
) => {
  const channel = `organization.${profile.appMetadata.affiliatedGroup}.query-modality-status`;

  const message = {
    message_guid: messageGuid,
    message_type: 'CHECK_MODALITY_STATUS',
    facility_guid: device.facility,
    modality_device: {
      device_guid: device.id,
      device_type: device.modality,
      dicom_ae_title: device.dicomAeTitle,
      ip_address: device.ipAddress,
      port: device.dicomPort
    },
    requested_at: moment(),
    requested_by: {
      email: profile.name,
      full_name: profile.name,
      user_id: profile.id
    }
  };

  pubnub.setUUID(profile.id);
  pubnub.publish(
    {
      channel,
      message
    },
    // eslint-disable-next-line func-names
    function(status, response) {
      if (status.error) {
        // console.error("PubNub publishing chat failed w/ status: ", JSON.stringify(status));
      }
    }
  );
};

export const deleteDeviceApiCall = async (profile: UserProfile, id: string) => {
  Logger.log('DELETE device api call');
  const Auth = await AuthService(profile.accessToken.length > 0);
  return new Auth(profile)
    .service(API_SERVICE, `${BASE_ROUTE}/${id}`, { method: 'DELETE' })
    .then(r => {
      Logger.log('DELETE device api response ', r);
      return r;
    });
};
